import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { ContactUsContent } from "@components/Shared/ContactUsRefactor/ContactUs";
import { TurretHeroContent } from "@components/TurretSteel/Hero/Hero";
import { ProductsAndServicesContent } from "@components/TurretSteel/ProductsAndServices/ProductsAndServices";
import { OurExpertsContent } from "@components/TurretSteel/OurExperts/OurExperts";
import { TurretIndustriesContent } from "@components/TurretSteel/IndustriesWeServe/IndustriesWeServe";
import { AwardsContent } from "@components/TurretSteel/Awards/Awards";
import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { PhoneNumbers } from "@ryerson/common.constants";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Turret Steel - Ryerson",
		description:
			"Turret Steel, part of the Ryerson Family of Companies, offers an extensive inventory of SBQ carbon and alloy grades.",
		url: `/about-us/family-of-companies/turret-steel`,
		company: true,
	},
	fr: {
		title: "Turret Steel - Ryerson",
		description:
			"Turret Steel, part of the Ryerson Family of Companies, offers an extensive inventory of SBQ carbon and alloy grades.",
		url: `/about-us/family-of-companies/turret-steel`,
		company: true,
	},
	es: {
		title: "Turret Steel - Ryerson",
		description:
			"Turret Steel, part of the Ryerson Family of Companies, offers an extensive inventory of SBQ carbon and alloy grades.",
		url: `/about-us/family-of-companies/turret-steel`,
		company: true,
	},
};

export const IndustriesContent: LanguageContent<TurretIndustriesContent> = {
	en: {
		title: "Industries We Serve",
		description: "",
		industries: [
			{
				title: "Forging",
				description: `We supply SBQ carbon and alloy steel bars and stainless steel bars to 
                    our customers that produce forgings to supply and service automotive, agricultural, 
                    mining, oil & gas (energy), and construction.`,
				bannerImageUrl: "/images/family-of-companies/turret-steel/Forging.jpg",
				industryComponents: [
					{
						title: "Steel",
						helpText: ["Help Me", "Select"],
						componentParts: [
							{
								title: "Bars",
								pieces: [
									{
										title: "Round",
										icon: "material-bar-round",
										url: "/store/carbon/bar/round?view=guided",
									},
									{
										title: "Hex",
										icon: "material-bar-hexagon",
										url: "/store/carbon/bar/hexagon?view=guided",
									},
									{
										title: "Flat",
										icon: "material-bar-flat",
										url: "/store/carbon/bar/flat?view=guided",
									},
									{
										title: "Square",
										icon: "material-bar-square",
										url: "/store/carbon/bar/square?view=guided",
									},
								],
							},
						],
					},
					{
						title: "Stainless Steel",
						helpText: ["Help Me", "Select"],
						componentParts: [
							{
								title: "Bars",
								pieces: [
									{
										title: "Round",
										icon: "material-bar-round",
										url: "/store/stainless-steel/bar/round?view=guided",
									},
									{
										title: "Hex",
										icon: "material-bar-hexagon",
										url: "/store/stainless-steel/bar/hexagon?view=guided",
									},
									{
										title: "Flat",
										icon: "material-bar-flat",
										url: "/store/stainless-steel/bar/flat?view=guided",
									},
									{
										title: "Square",
										icon: "material-bar-square",
										url: "/store/stainless-steel/bar/square?view=guided",
									},
								],
							},
						],
					},
					{
						title: "Alloy Steel",
						helpText: ["Help Me", "Select"],
						componentParts: [
							{
								title: "Bars",
								pieces: [
									{
										title: "Round",
										icon: "material-bar-round",
										url: "/store/alloy/bar/round?view=guided",
									},
									{
										title: "Hex",
										icon: "material-bar-hexagon",
										url: "/store/alloy/bar/hexagon?view=guided",
									},
									{
										title: "Flat",
										icon: "material-bar-flat",
										url: "/store/alloy/bar/flat?view=guided",
									},
									{
										title: "Square",
										icon: "material-bar-square",
										url: "/store/alloy/bar/square?view=guided",
									},
								],
							},
						],
					},
				],
			},
			{
				title: "Hand Tools",
				description: `We supply some of the leading manufacturers in North America with 
                    high carbon and alloy steel bars, such as rounds and hexes, to produce hand 
                    tools for virtually every industry. We can supply cold finished bars through 
                    our company-owned cold-drawing facility.`,
				bannerImageUrl: "/images/family-of-companies/turret-steel/HandTools.jpg",
				industryComponents: [
					{
						title: "Steel",
						helpText: ["Help Me", "Select"],
						componentParts: [
							{
								title: "Bars",
								pieces: [
									{
										title: "Round",
										icon: "material-bar-round",
										url: "/store/carbon/bar/round?view=guided",
									},
									{
										title: "Hex",
										icon: "material-bar-hexagon",
										url: "/store/carbon/bar/hexagon?view=guided",
									},
									{
										title: "Flat",
										icon: "material-bar-flat",
										url: "/store/carbon/bar/flat?view=guided",
									},
									{
										title: "Square",
										icon: "material-bar-square",
										url: "/store/carbon/bar/square?view=guided",
									},
								],
							},
						],
					},
					{
						title: "Alloy Steel",
						helpText: ["Help Me", "Select"],
						componentParts: [
							{
								title: "Bars",
								pieces: [
									{
										title: "Round",
										icon: "material-bar-round",
										url: "/store/alloy/bar/round?view=guided",
									},
									{
										title: "Hex",
										icon: "material-bar-hexagon",
										url: "/store/alloy/bar/hexagon?view=guided",
									},
									{
										title: "Flat",
										icon: "material-bar-flat",
										url: "/store/alloy/bar/flat?view=guided",
									},
									{
										title: "Square",
										icon: "material-bar-square",
										url: "/store/alloy/bar/square?view=guided",
									},
								],
							},
						],
					},
				],
			},
			{
				title: "Gun Barrel",
				description: `We supply GBQ Carbon and alloy steel bars, tested to required specifications 
                    for grades such as 1137, 4140/4140res, 4150/4150res and CrMoV; as well as stainless 
                    steel bars in grades such as 416r, 416 and 410.  steel goes into law enforcement, 
                    defense, and commercial applications.`,
				bannerImageUrl: "/images/family-of-companies/turret-steel/GunBarrel.jpg",
				industryComponents: [
					{
						title: "Steel",
						helpText: ["Help Me", "Select"],
						componentParts: [
							{
								title: "Bars",
								pieces: [
									{
										title: "Round",
										icon: "material-bar-round",
										url: "/store/carbon/bar/round?view=guided",
									},
								],
							},
						],
					},
					{
						title: "Alloy Steel",
						helpText: ["Help Me", "Select"],
						componentParts: [
							{
								title: "Bars",
								pieces: [
									{
										title: "Round",
										icon: "material-bar-round",
										url: "/store/alloy/bar/round?view=guided",
									},
								],
							},
						],
					},
					{
						title: "Stainless Steel",
						helpText: ["Help Me", "Select"],
						componentParts: [
							{
								title: "Bars",
								pieces: [
									{
										title: "Round",
										icon: "material-bar-round",
										url: "/store/stainless-steel/bar/round?view=guided",
									},
								],
							},
						],
					},
				],
			},
			{
				title: "Investment Casting",
				description: `We supply processed carbon and alloy steel bars that are cut/shot blasted/packaged 
                    into drums, boxes, or customer supplied metal bins. Our customers produce investment castings 
                    that support and service automotive, agricultural, mining, oil & gas (energy), construction 
                    and firearms industries.`,
				bannerImageUrl:
					"/images/family-of-companies/turret-steel/InvestmentCastingUpdate.jpg",
				industryComponents: [
					{
						title: "Steel",
						helpText: ["Help Me", "Select"],
						componentParts: [
							{
								title: "Bars",
								pieces: [
									{
										title: "Round",
										icon: "material-bar-round",
										url: "/store/carbon/bar/round?view=guided",
									},
									{
										title: "Hex",
										icon: "material-bar-hexagon",
										url: "/store/carbon/bar/hexagon?view=guided",
									},
									{
										title: "Flat",
										icon: "material-bar-flat",
										url: "/store/carbon/bar/flat?view=guided",
									},
									{
										title: "Square",
										icon: "material-bar-square",
										url: "/store/carbon/bar/square?view=guided",
									},
								],
							},
						],
					},
					{
						title: "Alloy Steel",
						helpText: ["Help Me", "Select"],
						componentParts: [
							{
								title: "Bars",
								pieces: [
									{
										title: "Round",
										icon: "material-bar-round",
										url: "/store/alloy/bar/round?view=guided",
									},
									{
										title: "Hex",
										icon: "material-bar-hexagon",
										url: "/store/alloy/bar/hexagon?view=guided",
									},
									{
										title: "Flat",
										icon: "material-bar-flat",
										url: "/store/alloy/bar/flat?view=guided",
									},
									{
										title: "Square",
										icon: "material-bar-square",
										url: "/store/alloy/bar/square?view=guided",
									},
								],
							},
						],
					},
					{
						title: "Stainless Steel",
						helpText: ["Help Me", "Select"],
						componentParts: [
							{
								title: "Bars",
								pieces: [
									{
										title: "Round",
										icon: "material-bar-round",
										url: "/store/stainless-steel/bar/round?view=guided",
									},
									{
										title: "Hex",
										icon: "material-bar-hexagon",
										url: "/store/stainless-steel/bar/hexagon?view=guided",
									},
									{
										title: "Flat",
										icon: "material-bar-flat",
										url: "/store/stainless-steel/bar/flat?view=guided",
									},
									{
										title: "Square",
										icon: "material-bar-square",
										url: "/store/stainless-steel/bar/square?view=guided",
									},
								],
							},
						],
					},
				],
			},
		],
	},
	fr: {
		title: "Les secteurs dans lesquels nous nous spécialisons",
		description: "",
		industries: [
			{
				title: "Pièces forgées",
				description: `Nous fournissons à nos clients des barres en acier inoxydable, en acier au carbone 
                    et en acier d'alliage SBQ qui produisent des pièces forgées destinées à l'approvisionnement 
                    et à l'entretien des secteurs de l'automobile, l'agriculture, l'exploitation minière, 
                    le pétrole et le gaz (énergie) et la construction. `,
				bannerImageUrl: "/images/family-of-companies/turret-steel/Forging.jpg",
				industryComponents: [
					{
						title: "Acier",
						helpText: ["J'ai besoin d'aide", "pour la sélection"],
						componentParts: [
							{
								title: "Bar",
								pieces: [
									{
										title: "Ronde",
										icon: "material-bar-round",
										url: "/store/carbon/bar/round?view=guided",
									},
									{
										title: "Hexagonale",
										icon: "material-bar-hexagon",
										url: "/store/carbon/bar/hexagon?view=guided",
									},
									{
										title: "Plate",
										icon: "material-bar-flat",
										url: "/store/carbon/bar/flat?view=guided",
									},
									{
										title: "Carrée",
										icon: "material-bar-square",
										url: "/store/carbon/bar/square?view=guided",
									},
								],
							},
						],
					},
					{
						title: "Acier inoxydable",
						helpText: ["J'ai besoin d'aide", "pour la sélection"],
						componentParts: [
							{
								title: "Bar",
								pieces: [
									{
										title: "Ronde",
										icon: "material-bar-round",
										url: "/store/stainless-steel/bar/round?view=guided",
									},
									{
										title: "Hexagonale",
										icon: "material-bar-hexagon",
										url: "/store/stainless-steel/bar/hexagon?view=guided",
									},
									{
										title: "Plate",
										icon: "material-bar-flat",
										url: "/store/stainless-steel/bar/flat?view=guided",
									},
									{
										title: "Carrée",
										icon: "material-bar-square",
										url: "/store/stainless-steel/bar/square?view=guided",
									},
								],
							},
						],
					},
					{
						title: "Alliage",
						helpText: ["J'ai besoin d'aide", "pour la sélection"],
						componentParts: [
							{
								title: "Bar",
								pieces: [
									{
										title: "Ronde",
										icon: "material-bar-round",
										url: "/store/alloy/bar/round?view=guided",
									},
									{
										title: "Hexagonale",
										icon: "material-bar-hexagon",
										url: "/store/alloy/bar/hexagon?view=guided",
									},
									{
										title: "Plate",
										icon: "material-bar-flat",
										url: "/store/alloy/bar/flat?view=guided",
									},
									{
										title: "Carrée",
										icon: "material-bar-square",
										url: "/store/alloy/bar/square?view=guided",
									},
								],
							},
						],
					},
				],
			},
			{
				title: "Outils à main",
				description: `Nous fournissons à certains des principaux fabricants d'Amérique du Nord des barres 
                    d'acier à haute teneur en carbone et en acier d'alliage, telles que des ronds et des hexagones, 
                    afin de produire des outils à main pour pratiquement tous les secteurs industriels. 
                    Les installations d'étirage à froid de notre entreprise nous permettent de fournir des barres 
                    finies à froid.`,
				bannerImageUrl: "/images/family-of-companies/turret-steel/HandTools.jpg",
				industryComponents: [
					{
						title: "Acier",
						helpText: ["J'ai besoin d'aide", "pour la sélection"],
						componentParts: [
							{
								title: "Bar",
								pieces: [
									{
										title: "Ronde",
										icon: "material-bar-round",
										url: "/store/carbon/bar/round?view=guided",
									},
									{
										title: "Hexagonale",
										icon: "material-bar-hexagon",
										url: "/store/carbon/bar/hexagon?view=guided",
									},
									{
										title: "Plate",
										icon: "material-bar-flat",
										url: "/store/carbon/bar/flat?view=guided",
									},
									{
										title: "Carrée",
										icon: "material-bar-square",
										url: "/store/carbon/bar/square?view=guided",
									},
								],
							},
						],
					},
					{
						title: "Alliage",
						helpText: ["J'ai besoin d'aide", "pour la sélection"],
						componentParts: [
							{
								title: "Bar",
								pieces: [
									{
										title: "Ronde",
										icon: "material-bar-round",
										url: "/store/alloy/bar/round?view=guided",
									},
									{
										title: "Hexagonale",
										icon: "material-bar-hexagon",
										url: "/store/alloy/bar/hexagon?view=guided",
									},
									{
										title: "Plate",
										icon: "material-bar-flat",
										url: "/store/alloy/bar/flat?view=guided",
									},
									{
										title: "Carrée",
										icon: "material-bar-square",
										url: "/store/alloy/bar/square?view=guided",
									},
								],
							},
						],
					},
				],
			},
			{
				title: "Canon",
				description: `Nous offrons des barres en acier au carbone et en alliage de qualité pour canons 
                    d'armes à feu, testées selon les spécifications requises pour les grades tels que 1137, 
                    4140/4140res, 4150/4150res et CrMoV, ainsi que des barres en acier inoxydable dans des 
                    grades tels que 416r, 416 et 410. Cet acier est utilisé dans les applications d'application 
                    de la loi, de défense et commerciales.`,
				bannerImageUrl: "/images/family-of-companies/turret-steel/GunBarrel.jpg",
				industryComponents: [
					{
						title: "Acier",
						helpText: ["J'ai besoin d'aide", "pour la sélection"],
						componentParts: [
							{
								title: "Bar",
								pieces: [
									{
										title: "Ronde",
										icon: "material-bar-round",
										url: "/store/carbon/bar/round?view=guided",
									},
								],
							},
						],
					},
					{
						title: "Alliage",
						helpText: ["J'ai besoin d'aide", "pour la sélection"],
						componentParts: [
							{
								title: "Bar",
								pieces: [
									{
										title: "Ronde",
										icon: "material-bar-round",
										url: "/store/alloy/bar/round?view=guided",
									},
								],
							},
						],
					},
					{
						title: "Acier inoxydable",
						helpText: ["J'ai besoin d'aide", "pour la sélection"],
						componentParts: [
							{
								title: "Bar",
								pieces: [
									{
										title: "Ronde",
										icon: "material-bar-round",
										url: "/store/stainless-steel/bar/round?view=guided",
									},
								],
							},
						],
					},
				],
			},
			{
				title: "Moulage à la cire perdue",
				description: `Nous offrons des barres en acier au carbone traité et en acier d'alliage qui sont 
                    coupées/grenées/conditionnées dans des fûts, des boîtes ou des bacs métalliques fournis par 
                    le client. Nos clients produisent des moulages à la cire perdue destinés à l'approvisionnement 
                    et à l'entretien des secteurs de l'automobile, l'agriculture, l'exploitation minière, 
                    le pétrole et le gaz (énergie), les armes à feu.`,
				bannerImageUrl:
					"/images/family-of-companies/turret-steel/InvestmentCastingUpdate.jpg",
				industryComponents: [
					{
						title: "Acier",
						helpText: ["J'ai besoin d'aide", "pour la sélection"],
						componentParts: [
							{
								title: "Bar",
								pieces: [
									{
										title: "Ronde",
										icon: "material-bar-round",
										url: "/store/carbon/bar/round?view=guided",
									},
									{
										title: "Hexagonale",
										icon: "material-bar-hexagon",
										url: "/store/carbon/bar/hexagon?view=guided",
									},
									{
										title: "Plate",
										icon: "material-bar-flat",
										url: "/store/carbon/bar/flat?view=guided",
									},
									{
										title: "Carrée",
										icon: "material-bar-square",
										url: "/store/carbon/bar/square?view=guided",
									},
								],
							},
						],
					},
					{
						title: "Alliage",
						helpText: ["J'ai besoin d'aide", "pour la sélection"],
						componentParts: [
							{
								title: "Bar",
								pieces: [
									{
										title: "Ronde",
										icon: "material-bar-round",
										url: "/store/alloy/bar/round?view=guided",
									},
									{
										title: "Hexagonale",
										icon: "material-bar-hexagon",
										url: "/store/alloy/bar/hexagon?view=guided",
									},
									{
										title: "Plate",
										icon: "material-bar-flat",
										url: "/store/alloy/bar/flat?view=guided",
									},
									{
										title: "Carrée",
										icon: "material-bar-square",
										url: "/store/alloy/bar/square?view=guided",
									},
								],
							},
						],
					},
					{
						title: "Acier inoxydable",
						helpText: ["J'ai besoin d'aide", "pour la sélection"],
						componentParts: [
							{
								title: "Bar",
								pieces: [
									{
										title: "Ronde",
										icon: "material-bar-round",
										url: "/store/stainless-steel/bar/round?view=guided",
									},
									{
										title: "Hexagonale",
										icon: "material-bar-hexagon",
										url: "/store/stainless-steel/bar/hexagon?view=guided",
									},
									{
										title: "Plate",
										icon: "material-bar-flat",
										url: "/store/stainless-steel/bar/flat?view=guided",
									},
									{
										title: "Carrée",
										icon: "material-bar-square",
										url: "/store/stainless-steel/bar/square?view=guided",
									},
								],
							},
						],
					},
				],
			},
		],
	},
	es: {
		title: "Industrias a la que servimos",
		description: "",
		industries: [
			{
				title: "Forjado",
				description: `Ofrecemos barras de carbono y barras de acero de aleación y de acero 
                    inoxidable a nuestros clientes que producen forjados para suministrar y servir 
                    a las industrias automotriz, agrícola, minera, de petróleo y gas (energía) 
                    y de construcción.`,
				bannerImageUrl: "/images/family-of-companies/turret-steel/Forging.jpg",
				industryComponents: [
					{
						title: "Acero",
						helpText: ["Ayudarme a", "seleccionar"],
						componentParts: [
							{
								title: "Bar",
								pieces: [
									{
										title: "Redonda",
										icon: "material-bar-round",
										url: "/store/carbon/bar/round?view=guided",
									},
									{
										title: "Hexagonal",
										icon: "material-bar-hexagon",
										url: "/store/carbon/bar/hexagon?view=guided",
									},
									{
										title: "Pletina",
										icon: "material-bar-flat",
										url: "/store/carbon/bar/flat?view=guided",
									},
									{
										title: "Cuadrada",
										icon: "material-bar-square",
										url: "/store/carbon/bar/square?view=guided",
									},
								],
							},
						],
					},
					{
						title: "Acero inoxidable",
						helpText: ["Ayudarme a", "seleccionar"],
						componentParts: [
							{
								title: "Bar",
								pieces: [
									{
										title: "Redonda",
										icon: "material-bar-round",
										url: "/store/stainless-steel/bar/round?view=guided",
									},
									{
										title: "Hexagonal",
										icon: "material-bar-hexagon",
										url: "/store/stainless-steel/bar/hexagon?view=guided",
									},
									{
										title: "Pletina",
										icon: "material-bar-flat",
										url: "/store/stainless-steel/bar/flat?view=guided",
									},
									{
										title: "Cuadrada",
										icon: "material-bar-square",
										url: "/store/stainless-steel/bar/square?view=guided",
									},
								],
							},
						],
					},
					{
						title: "Aleación",
						helpText: ["Ayudarme a", "seleccionar"],
						componentParts: [
							{
								title: "Bar",
								pieces: [
									{
										title: "Redonda",
										icon: "material-bar-round",
										url: "/store/alloy/bar/round?view=guided",
									},
									{
										title: "Hexagonal",
										icon: "material-bar-hexagon",
										url: "/store/alloy/bar/hexagon?view=guided",
									},
									{
										title: "Pletina",
										icon: "material-bar-flat",
										url: "/store/alloy/bar/flat?view=guided",
									},
									{
										title: "Cuadrada",
										icon: "material-bar-square",
										url: "/store/alloy/bar/square?view=guided",
									},
								],
							},
						],
					},
				],
			},
			{
				title: "Herramientas manuales",
				description: `Proveemos a algunos de los fabricantes líderes de Norteamérica barras 
                    de alto contenido de carbono y de acero de aleación, tales como redondas y hexagonales, 
                    para producir herramientas manuales para virtualmente todas las industrias. Podemos 
                    proveer barras de acabado en frío a través de nuestra planta propia de extracción en 
                    frío.`,
				bannerImageUrl: "/images/family-of-companies/turret-steel/HandTools.jpg",
				industryComponents: [
					{
						title: "Acero",
						helpText: ["Ayudarme a", "seleccionar"],
						componentParts: [
							{
								title: "Bar",
								pieces: [
									{
										title: "Redonda",
										icon: "material-bar-round",
										url: "/store/carbon/bar/round?view=guided",
									},
									{
										title: "Hexagonal",
										icon: "material-bar-hexagon",
										url: "/store/carbon/bar/hexagon?view=guided",
									},
									{
										title: "Pletina",
										icon: "material-bar-flat",
										url: "/store/carbon/bar/flat?view=guided",
									},
									{
										title: "Cuadrada",
										icon: "material-bar-square",
										url: "/store/carbon/bar/square?view=guided",
									},
								],
							},
						],
					},
					{
						title: "Aleación",
						helpText: ["Ayudarme a", "seleccionar"],
						componentParts: [
							{
								title: "Bar",
								pieces: [
									{
										title: "Redonda",
										icon: "material-bar-round",
										url: "/store/alloy/bar/round?view=guided",
									},
									{
										title: "Hexagonal",
										icon: "material-bar-hexagon",
										url: "/store/alloy/bar/hexagon?view=guided",
									},
									{
										title: "Pletina",
										icon: "material-bar-flat",
										url: "/store/alloy/bar/flat?view=guided",
									},
									{
										title: "Cuadrada",
										icon: "material-bar-square",
										url: "/store/alloy/bar/square?view=guided",
									},
								],
							},
						],
					},
				],
			},
			{
				title: "Cañón de armas de fuego",
				description: `Proveemos barras de carbono y de acero de aleación GBQ probadas según las 
                    especificaciones requeridas para grados como 1137, 4140/4140res, 4150/4150res y CrMoV, 
                    así como barras de acero inoxidable en grados como 416r, 416 y 410. El acero se emplea 
                    en aplicaciones para uso del orden público, defensa y comercial.`,
				bannerImageUrl: "/images/family-of-companies/turret-steel/GunBarrel.jpg",
				industryComponents: [
					{
						title: "Acero",
						helpText: ["Ayudarme a", "seleccionar"],
						componentParts: [
							{
								title: "Bar",
								pieces: [
									{
										title: "Redonda",
										icon: "material-bar-round",
										url: "/store/carbon/bar/round?view=guided",
									},
								],
							},
						],
					},
					{
						title: "Aleación",
						helpText: ["Ayudarme a", "seleccionar"],
						componentParts: [
							{
								title: "Bar",
								pieces: [
									{
										title: "Redonda",
										icon: "material-bar-round",
										url: "/store/alloy/bar/round?view=guided",
									},
								],
							},
						],
					},
					{
						title: "Acero inoxidable",
						helpText: ["Ayudarme a", "seleccionar"],
						componentParts: [
							{
								title: "Bar",
								pieces: [
									{
										title: "Redonda",
										icon: "material-bar-round",
										url: "/store/stainless-steel/bar/round?view=guided",
									},
								],
							},
						],
					},
				],
			},
			{
				title: "Forjado de inversión",
				description: `Proveemos barras de carbono y acero de aleación cortadas, granalladas o 
                    empaquetadas en tambos, cajas o contenedores de metal proporcionados por el cliente. 
                    Nuestros clientes producen forjados de inversión que dan apoyo y servicio a la 
                    industria automotriz, agrícola, minera, del petróleo y gas (energía) y de fabricación 
                    de armas de fuego.`,
				bannerImageUrl:
					"/images/family-of-companies/turret-steel/InvestmentCastingUpdate.jpg",
				industryComponents: [
					{
						title: "Acero",
						helpText: ["Ayudarme a", "seleccionar"],
						componentParts: [
							{
								title: "Bar",
								pieces: [
									{
										title: "Redonda",
										icon: "material-bar-round",
										url: "/store/carbon/bar/round?view=guided",
									},
									{
										title: "Hexagonal",
										icon: "material-bar-hexagon",
										url: "/store/carbon/bar/hexagon?view=guided",
									},
									{
										title: "Pletina",
										icon: "material-bar-flat",
										url: "/store/carbon/bar/flat?view=guided",
									},
									{
										title: "Cuadrada",
										icon: "material-bar-square",
										url: "/store/carbon/bar/square?view=guided",
									},
								],
							},
						],
					},
					{
						title: "Aleación",
						helpText: ["Ayudarme a", "seleccionar"],
						componentParts: [
							{
								title: "Bar",
								pieces: [
									{
										title: "Redonda",
										icon: "material-bar-round",
										url: "/store/alloy/bar/round?view=guided",
									},
									{
										title: "Hexagonal",
										icon: "material-bar-hexagon",
										url: "/store/alloy/bar/hexagon?view=guided",
									},
									{
										title: "Pletina",
										icon: "material-bar-flat",
										url: "/store/alloy/bar/flat?view=guided",
									},
									{
										title: "Cuadrada",
										icon: "material-bar-square",
										url: "/store/alloy/bar/square?view=guided",
									},
								],
							},
						],
					},
					{
						title: "Acero inoxidable",
						helpText: ["Ayudarme a", "seleccionar"],
						componentParts: [
							{
								title: "Bar",
								pieces: [
									{
										title: "Redonda",
										icon: "material-bar-round",
										url: "/store/stainless-steel/bar/round?view=guided",
									},
									{
										title: "Hexagonal",
										icon: "material-bar-hexagon",
										url: "/store/stainless-steel/bar/hexagon?view=guided",
									},
									{
										title: "Pletina",
										icon: "material-bar-flat",
										url: "/store/stainless-steel/bar/flat?view=guided",
									},
									{
										title: "Cuadrada",
										icon: "material-bar-square",
										url: "/store/stainless-steel/bar/square?view=guided",
									},
								],
							},
						],
					},
				],
			},
		],
	},
};

export const HeroContent: LanguageContent<TurretHeroContent> = {
	en: {
		title: "Turret Steel",
		subTitle:
			"When it comes to SBQ (special bar quality) products and services, we have you covered.",
		description: [
			`Turret Steel, part of the Ryerson Family of Companies, offers an extensive inventory of SBQ 
                carbon and alloy grades. This includes a wide range of shapes from rounds, squares, and 
                flats, to round-cornered squares, hexagons, billets, and blooms, all ranging in size from 
                .25" — 32".`,
			`Our custom processing services include saw cutting, cold finishing, and heat treating.`,
			`With access to one of the broadest service center networks across North America, that means 
                one purchase order fulfills all your material, processing, and logistical needs.`,
		],
		logoUrl: "/images/family-of-companies/turretSteelLogo.png",
		imageUrl: "/images/family-of-companies/turret-steel/turret-steel-hero.jpg",
	},
	fr: {
		title: "Turret Steel",
		subTitle:
			"En ce qui concerne les produits et services SBQ (qualité spéciale pour barres), avec nous, vous êtes couvert.",
		description: [
			`Turret Steel, qui fait partie de la famille d'entreprises Ryerson, propose un large éventail 
                de grades d'acier au carbone et d'alliage de qualité spéciale pour barres (SBQ). Cela 
                comprend une large gamme de formes allant des ronds, des carrés et des plats aux carrés 
                à coins arrondis, aux hexagones, aux billettes et aux blooms, toutes en largeurs allant 
                de 0,25 à 32 pouces.`,
			`Nos services de traitement sur mesure comprennent la découpe à la scie, la finition à froid 
                et le traitement thermique.`,
			`Avec l'accès à l'un des plus vastes réseaux de centres de service en Amérique du Nord, cela 
                signifie qu'un seul bon de commande répond à tous vos besoins en matière de matériel, 
                de traitement et de logistique.`,
		],
		logoUrl: "/images/family-of-companies/turretSteelLogo.png",
		imageUrl: "/images/family-of-companies/TurretSteelHero.jpg",
	},
	es: {
		title: "Turret Steel",
		subTitle:
			"Cuando se trata de productos y servicios SBQ (calidad de barra especial), tenemos todo lo que usted necesita.",
		description: [
			`Turret Steel, parte de la Familia de compañías Ryerson, ofrece un extenso inventario de grados 
                de carbono y aleación de SBQ. Esto incluye un amplio rango de formas desde redondas, cuadradas 
                y planas, hasta redondas con esquinas redondeadas, hexágonos, lingotes y lupias, todos de 
                tamaños de 0.25" a 32".`,
			`Nuestros servicios de procesamiento personalizados incluyen corte con sierra, acabado en 
                frío y tratamiento térmico.`,
			`Con acceso a una de las redes centrales de servicio más extensas en Norteamérica, eso significa 
                que una orden de compra abarca todas sus necesidades de material, procesamiento y logísticas.`,
		],
		logoUrl: "/images/family-of-companies/turretSteelLogo.png",
		imageUrl: "/images/family-of-companies/TurretSteelHero.jpg",
	},
};

export const ContactContent: LanguageContent<ContactUsContent> = {
	en: {
		title: "Contact our team",
		titleSpecial:
			"\nof professionals for help with \n hard-to-find, materials, \n grades, and sizes",
		navLabel: "Contact Us",
		button1: "Request a Quote",
		button2: "Chat with Metal Professional",
		button1Link: "/contact-us#quoteRequest",
	},
	fr: {
		title: "Communiquez avec notre",
		titleSpecial:
			"\néquipe d’experts pour \n obtenir de l’aide sur les \n matériaux, les grades et \n les tailles difficiles à trouver",
		navLabel: "Communiquez",
		button1: "Demander un devis",
		button2: "Clavarder avec un expert des produits de métal",
		button1Link: "/contact-us#quoteRequest",
	},
	es: {
		title: "Póngase en contacto",
		titleSpecial:
			"\ncon los expertos para \n ayuda con materiales, \n grados y tamaños \n difíciles de encontrar",
		navLabel: "Contacto",
		button1: "Solicite un presupuesto",
		button2: "Hable con un experto en materiales",
		button1Link: "/contact-us#quoteRequest",
	},
};

export const ProductsServicesContent: LanguageContent<ProductsAndServicesContent> = {
	en: {
		title: "Our SBQ Products and Services",
		cards: [
			{
				title: "SBQ Carbon Steel & Alloy Grades",
				imageUrl: "/images/family-of-companies/turret-steel/SBQSquareBar.png",
				column_1: ["1000", "1100", "1200", "1500", "4100", "4800"],
				column_2: ["4340", "5100", "8600", "8700", "9300", "52100"],
			},
			{
				title: "Material Conditions",
				imageUrl: "/images/family-of-companies/turret-steel/SBQRoundBar.png",
				column_1: [
					"Cold Drawn",
					"Turned & Polished",
					"Turned, Ground & Polished",
					"Hot Rolled — As Rolled Annealed",
					"Quench — Tempered",
					"Machine — Straightened",
				],
			},
			{
				title: "Value-Added Services",
				imageUrl: "/images/family-of-companies/turret-steel/SBQValueAdded.png",
				column_1: [
					"Precision Saw",
					"Cutting and Packaging",
					'Large Bar Sawing up to 32" OD',
					"Bar Blasting",
					"Chamfering",
					"Cold Finishing",
					"Investment — Casting Process",
				],
			},
		],
	},
	fr: {
		title: "Nos produits et services d'acier SBQ (qualité spéciale pour barres)",
		cards: [
			{
				title: "Grades d'acier au carbone et alliage SBQ",
				imageUrl: "/images/family-of-companies/turret-steel/SBQSquareBar.png",
				column_1: ["1000", "1100", "1200", "1500", "4100", "4800"],
				column_2: ["4340", "5100", "8600", "8700", "9300", "52100"],
			},
			{
				title: "Conditions matérielles",
				imageUrl: "/images/family-of-companies/turret-steel/SBQRoundBar.png",
				column_1: [
					"Étiré à froid",
					"Tourné et poli",
					"Tourné, rectifié et poli",
					"Laminé à chaud, roulé recuit",
					"Trempé, et revenu",
					"Redressé mécaniquement",
				],
			},
			{
				title: "Services à valeur ajoutée",
				imageUrl: "/images/family-of-companies/turret-steel/SBQValueAdded.png",
				column_1: [
					"Découpage de précision",
					"Découpe et emballage",
					"Scie de grosses barres, jusqu'à 32 po de diamètre externe",
					"Décapage de barres",
					"Chanfreinage",
					"Fini à froid",
					"Processus de moulage à la cire perdue ",
				],
			},
		],
	},
	es: {
		title: "Nuestros productos y servicios SBQ (calidad de barra especial)",
		cards: [
			{
				title: "Grados de acero al carbono y aleación SBQ",
				imageUrl: "/images/family-of-companies/turret-steel/SBQSquareBar.png",
				column_1: ["1000", "1100", "1200", "1500", "4100", "4800"],
				column_2: ["4340", "5100", "8600", "8700", "9300", "52100"],
			},
			{
				title: "Condiciones de materiales",
				imageUrl: "/images/family-of-companies/turret-steel/SBQRoundBar.png",
				column_1: [
					"Extraídos en frío",
					"Torneados y pulidos",
					"Torneado, esmerilados y pulidos",
					"Enrollados en caliente como atemperados enrollados",
					"Templados",
					"Maquinados, enderezados ",
				],
			},
			{
				title: "Servicios de valor agregado",
				imageUrl: "/images/family-of-companies/turret-steel/SBQValueAdded.png",
				column_1: [
					"Sierra de precisión",
					"Corte y empaquetado",
					"Serrado de barra larga de hasta 32” de diámetro externo",
					"Granallado de barras",
					"Biselado",
					"Acabado en frío",
					"Proceso de fundido de inversión ",
				],
			},
		],
	},
};

export const ExpertsContent: LanguageContent<OurExpertsContent> = {
	en: {
		title: "Our Experts",
		description:
			"Work with our team of market and industry professionals on your next project.",
		logoUrl: "/images/family-of-companies/turret-steel/TurretSteelLogoBlue.png",
		companyName: "Turret Steel",
		address1: "310 Tech Drive",
		address2: "Burns Harbor IN 46304-8843​",
		phone: "Toll Free 800-245-4800",
		questionsLine: "Questions? Call us on",
		questionsPhone: PhoneNumbers.RYERSON_US.number,
		experts: [
			{
				name: "Gregory F. Otto",
				title: "General Manager",
				imageUrl: "/images/family-of-companies/turret-steel/GregOtto.jpg",
			},
			{
				name: "Neil Stein",
				title: "Outside Sales Manager",
				imageUrl: "/images/family-of-companies/turret-steel/NeilSteinUpdate.jpg",
			},
			{
				name: "Rob Glazier",
				title: "Relationship Manager",
				imageUrl: "/images/family-of-companies/turret-steel/RobGlazier.jpg",
			},
			{
				name: "Gary Roggenbuck",
				title: "Solutions Advisor",
				imageUrl: "/images/family-of-companies/turret-steel/GregRoggenbuck.jpg",
			},
		],
	},
	fr: {
		title: "Nos experts",
		description:
			"Travaillez avec notre équipe de professionnels du marché et de l’industrie sur votre prochain projet.",
		logoUrl: "/images/family-of-companies/turret-steel/TurretSteelLogoBlue.png",
		companyName: "Turret Steel",
		address1: "310 Tech Drive",
		address2: "Burns Harbor IN 46304-8843​",
		phone: "Toll Free 800-245-4800",
		questionsLine: "Des questions? Appelez-nous au",
		questionsPhone: PhoneNumbers.RYERSON_US.number,
		experts: [
			{
				name: "Gregory F. Otto",
				title: "General Manager",
				imageUrl: "/images/family-of-companies/turret-steel/GregOtto.jpg",
			},
			{
				name: "Neil Stein",
				title: "Outside Sales Manager",
				imageUrl: "/images/family-of-companies/turret-steel/NeilSteinUpdate.jpg",
			},
			{
				name: "Rob Glazier",
				title: "Relationship Manager",
				imageUrl: "/images/family-of-companies/turret-steel/RobGlazier.jpg",
			},
			{
				name: "Gary Roggenbuck",
				title: "Solutions Advisor",
				imageUrl: "/images/family-of-companies/turret-steel/GregRoggenbuck.jpg",
			},
		],
	},
	es: {
		title: "Nuestros expertos",
		description:
			"Trabaje con nuestro equipo de profesionales de mercado y de la industria en su siguiente proyecto.",
		logoUrl: "/images/family-of-companies/turret-steel/TurretSteelLogoBlue.png",
		companyName: "Turret Steel",
		address1: "310 Tech Drive",
		address2: "Burns Harbor IN 46304-8843​",
		phone: "Toll Free 800-245-4800",
		questionsLine: "¿Preguntas? Llámenos al",
		questionsPhone: PhoneNumbers.RYERSON_US.number,
		experts: [
			{
				name: "Gregory F. Otto",
				title: "General Manager",
				imageUrl: "/images/family-of-companies/turret-steel/GregOtto.jpg",
			},
			{
				name: "Neil Stein",
				title: "Outside Sales Manager",
				imageUrl: "/images/family-of-companies/turret-steel/NeilSteinUpdate.jpg",
			},
			{
				name: "Rob Glazier",
				title: "Relationship Manager",
				imageUrl: "/images/family-of-companies/turret-steel/RobGlazier.jpg",
			},
			{
				name: "Gary Roggenbuck",
				title: "Solutions Advisor",
				imageUrl: "/images/family-of-companies/turret-steel/GregRoggenbuck.jpg",
			},
		],
	},
};

export const AwardsMultilingualContent: LanguageContent<AwardsContent> = {
	en: {
		title: "Awards & Certifications",
		cards: [
			{
				imageUrl: "/images/family-of-companies/turret-steel/turret-iso-9001.jpg",
				url: "/static-assets/documents/pdfs/en/compliance-certifications/TurretSteelISO9001.pdf",
			},
		],
	},
	fr: {
		title: "Récompenses et certifications",
		cards: [
			{
				imageUrl: "/images/family-of-companies/turret-steel/turret-iso-9001.jpg",
				url: "/static-assets/documents/pdfs/fr/compliance-certifications/TurretSteelISO9001.pdf",
			},
		],
	},
	es: {
		title: "Premios y certificaciones",
		cards: [
			{
				imageUrl: "/images/family-of-companies/turret-steel/turret-iso-9001.jpg",
				url: "/static-assets/documents/pdfs/es/compliance-certifications/TurretSteelISO9001.pdf",
			},
		],
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [HeroContent, IndustriesContent, ProductsServicesContent],
};
