import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { HeroSectionContent } from "@components/Solutions/Hero/Hero";
import { SlideImageTextContent } from "@components/Solutions/SlideImageText/SlideImageText";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Solutions We Offer - Ryerson",
		description:
			"We understand that every customer need is unique. We leverage more than 180 years of experience to deliver customized metal solutions.",
		url: `/metal-solutions/solutions`,
		company: true,
	},
	fr: {
		title: "Solutions We Offer - Ryerson",
		description:
			"We understand that every customer need is unique. We leverage more than 180 years of experience to deliver customized metal solutions.",
		url: `/metal-solutions/solutions`,
		company: true,
	},
	es: {
		title: "Solutions We Offer - Ryerson",
		description:
			"We understand that every customer need is unique. We leverage more than 180 years of experience to deliver customized metal solutions.",
		url: `/metal-solutions/solutions`,
		company: true,
	},
};

export const heroSection: LanguageContent<HeroSectionContent> = {
	en: {
		title: "Solutions",
		description:
			"We understand that every customer need is unique. We leverage more than 180 years of experience to deliver customized metal solutions. Explore which solution best fits your needs.",
		yearsInBusiness: "180+",
		metalProducts: "70,000+",
		yearsInBusinessLabel: "Years in Business",
		metalProductsLabel: "Metal Products",
	},
	fr: {
		title: "Solutions",
		description:
			"Nous comprenons que les besoins de chaque client sont uniques. Nous tirons parti de plus de 180 années d'expérience pour offrir des solutions en métal personnalisées. Trouvez la solution qui répond le mieux à vos besoins",
		yearsInBusiness: "180+",
		metalProducts: "70,000+",
		yearsInBusinessLabel: "Ans d'activité",
		metalProductsLabel: "Produits métalliques",
	},
	es: {
		title: "Soluciones",
		description:
			"Sabemos que la necesidad de cada cliente es única. Aprovechamos más de 180 años de experiencia para brindar al cliente soluciones de metales personalizadas. Exploremos cuál solución es la más adecuada para sus necesidades.",
		yearsInBusiness: "180+",
		metalProducts: "70,000+",
		yearsInBusinessLabel: "Años en negocios",
		metalProductsLabel: "Productos metálicos",
	},
};

export const slideImageText: LanguageContent<SlideImageTextContent[]> = {
	en: [
		{
			imageUrl: "/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
			title: "Advanced Processing",
			description:
				"We know you need more than just a list of processing capabilities. Our team of processing and fabrication professionals customize processing solutions to help lower costs, reduce waste, and manage capacity specific to your business.",
			jumpToLink: "/metal-solutions/solutions/advanced-processing/",
			jumpToText: "More about Advanced Processing",
		},
		{
			imageUrl: "/images/metal-solutions/solutions/solutions/GlobalAccountsUpdate3.jpg",
			title: "Global Accounts",
			description:
				"Are you a large OEM with multiple manufacturing locations across North America? Let us be your single point of contact for across the supply chain so you can focus on what you do best.",
			jumpToLink: "/metal-solutions/solutions/global-accounts/",
			jumpToText: "More about Global Accounts",
		},
		{
			imageUrl: "/images/metal-solutions/solutions/solutions/CarbonSheetSolutions.jpg",
			title: "Carbon Sheet Solutions",
			description:
				"Do you buy carbon flat roll in large quantities? Not sure if you are getting the right price or using the most optimal supply chain? Our team of professionals has a solution for you.",
			jumpToLink: "/metal-solutions/solutions/carbon-sheet-solutions/",
			jumpToText: "More about Carbon Sheet Solutions",
		},
		{
			imageUrl:
				"/images/metal-solutions/solutions/solutions/CustomAluminumExtrusionsUpdate.png",
			title: "Custom Aluminum Extrusions",
			description:
				"Do you need complex designs that are uniform across the material length? We take a custom approach to your aluminum extrusion project.",
			jumpToLink: "/metal-solutions/solutions/custom-aluminum-extrusions/",
			jumpToText: "More about Custom Aluminum Extrusions",
		},
	],
	fr: [
		{
			imageUrl: "/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
			title: "Traitement avancé",
			description:
				"Nous savons que vous avez besoin de plus qu'une simple liste de capacités de traitement. Notre équipe d'experts en traitement et en fabrication personnalise les solutions de traitement pour aider à réduire les coûts, à réduire les déchets et à gérer la capacité propre à votre entreprise.",
			jumpToLink: "/metal-solutions/solutions/advanced-processing/",
			jumpToText: "En savoir plus sur le traitement avancé",
		},
		{
			imageUrl: "/images/metal-solutions/solutions/solutions/GlobalAccountsUpdate3.jpg",
			title: "Comptes globaux",
			description:
				"Vous êtes un grand fabricant d'équipement d'origine ayant plusieurs sites de fabrication en Amérique du Nord? Laissez-nous être votre point de contact unique pour l'ensemble de votre chaîne d'approvisionnement, afin que vous puissiez vous concentrer sur ce que vous faites le mieux.",
			jumpToLink: "/metal-solutions/solutions/global-accounts/",
			jumpToText: "En savoir plus sur les comptes globaux",
		},
		{
			imageUrl: "/images/metal-solutions/solutions/solutions/CarbonSheetSolutions.jpg",
			title: "Solutions en feuille d'acier au carbone",
			description:
				"Vous achetez de grandes quantités de rouleaux d'acier au carbone laminé? Vous ne savez pas si vous obtenez le bon prix ou si vous utilisez la chaîne d'approvisionnement la plus optimale? Notre équipe d'experts a une solution pour vous.",
			jumpToLink: "/metal-solutions/solutions/carbon-sheet-solutions/",
			jumpToText: "En savoir plus sur les solutions en feuille d'acier au carbone",
		},
		{
			imageUrl:
				"/images/metal-solutions/solutions/solutions/CustomAluminumExtrusionsUpdate.png",
			title: "Extrusions en aluminium personnalisées",
			description:
				"Vous avez besoin de conceptions complexes uniformes sur toute la longueur du matériau? Nous adoptons une approche personnalisée pour vos projets d'extrusion d'aluminium.",
			jumpToLink: "/metal-solutions/solutions/custom-aluminum-extrusions/",
			jumpToText: "En savoir plus sur les extrusions en aluminium personnalisées",
		},
	],
	es: [
		{
			imageUrl: "/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
			title: "Procesamiento avanzado",
			description:
				"Sabemos que necesita más que una lista de capacidades de procesamiento. Nuestro equipo de expertos en procesamiento y fabricación diseñan soluciones de procesamiento para ayudar a reducir costos, disminuir el desperdicio y manejar una capacidad específica para su negocio.",
			jumpToLink: "/metal-solutions/solutions/advanced-processing/",
			jumpToText: "Más sobre procesamiento avanzado",
		},
		{
			imageUrl: "/images/metal-solutions/solutions/solutions/GlobalAccountsUpdate3.jpg",
			title: "Cuentas globales",
			description:
				"¿Es usted fabricante de equipos originales con una gran cantidad de plantas de fabricación en Norteamérica? Permítanos ser su punto único de contacto para toda la cadena de suministro de manera que usted pueda concentrarse en lo que mejor sabe hacer.",
			jumpToLink: "/metal-solutions/solutions/global-accounts/",
			jumpToText: "Más sobre cuentas globales",
		},
		{
			imageUrl: "/images/metal-solutions/solutions/solutions/CarbonSheetSolutions.jpg",
			title: "Soluciones de placas de carbono",
			description:
				"¿Compra rollos planos de carbono en grandes cantidades? ¿No sabe si obtiene el precio correcto o usa la cadena de suministro óptima? Nuestro equipo de expertos tiene una solución para usted.",
			jumpToLink: "/metal-solutions/solutions/carbon-sheet-solutions/",
			jumpToText: "Más sobre soluciones de placas de carbono",
		},
		{
			imageUrl:
				"/images/metal-solutions/solutions/solutions/CustomAluminumExtrusionsUpdate.png",
			title: "Extrusiones de aluminio personalizadas",
			description:
				"¿Necesita diseños complejos que sean uniformes en toda la longitud del material? Adoptamos un enfoque personalizado para su proyecto de extrusión de aluminio.",
			jumpToLink: "/metal-solutions/solutions/custom-aluminum-extrusions/",
			jumpToText: "Más sobre extrusiones de aluminio personalizadas",
		},
	],
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [heroSection, slideImageText],
};
