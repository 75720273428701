import React from "react";
import { Media } from "@ryerson/frontend.layout";
import OurExpertsDesktop from "./Desktop/OurExpertsDesktop";
import OurExpertsMobile from "./Mobile/OurExpertsMobile";

export type TurretExpert = {
	name: string;
	title: string;
	imageUrl: string;
};

export type OurExpertsContent = {
	title: string;
	description: string;
	logoUrl: string;
	companyName: string;
	address1: string;
	address2: string;
	phone: string;
	questionsLine: string;
	questionsPhone: string;
	experts: TurretExpert[];
};

export type OurExpertsProps = {
	content: OurExpertsContent;
};

const OurExperts: React.FC<OurExpertsProps> = ({ content }) => {
	return (
		<>
			<Media lessThan="lg">
				<OurExpertsMobile content={content} />
			</Media>
			<Media greaterThanOrEqual="lg">
				<OurExpertsDesktop content={content} />
			</Media>
		</>
	);
};

export default OurExperts;
