import React from "react";
import HomePage from "@components/HomePage/homepage";
import Layout from "@components/Shared/Templates/Layout";

export default () => {
	return (
		<Layout>
			<HomePage />
		</Layout>
	);
};
