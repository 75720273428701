import { SolutionsWeOfferContent } from "./SolutionsWeOffer";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const SolutionsContent: LanguageContent<SolutionsWeOfferContent> = {
	en: {
		title1: "Solutions",
		title2: "We Offer",
		title: "Solutions We Offer",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "More Metal \n Solutions",
		description: `We understand that every customer need is unique. We leverage more than 180 years of experience to deliver customized metal solutions. Explore which solution best fits your needs.`,
		buttonLabel: "Lets Talk!",
		buttonUrl: "/contact-us#productQuestion",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Advanced \n Processing",
				backgroundUrl: "/images/homepage/solutions-we-offer/AdvancedProcessing.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Global \n Accounts",
				backgroundUrl: "/images/homepage/solutions-we-offer/GlobalAccounts.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Carbon Sheet \n Solutions",
				backgroundUrl: "/images/homepage/solutions-we-offer/CarbonSheetSolutions.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Custom Aluminum \n Extrusions",
				backgroundUrl: "/images/homepage/solutions-we-offer/CustomAluminumExtrusions.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
	fr: {
		title1: "Nos",
		title2: "solutions",
		title: "Nos solutions",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Plus de solutions \n métalliques",
		description: `Nous comprenons que les besoins de chaque client sont uniques. Nous tirons parti de plus de 180 années d'expérience pour offrir des solutions en métal personnalisées. Trouvez la solution qui répond le mieux à vos besoins.`,
		buttonLabel: "Discutons",
		buttonUrl: "/contact-us#productQuestion",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Traitement \n avancé",
				backgroundUrl: "/images/homepage/solutions-we-offer/AdvancedProcessing.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Comptes \n internationaux",
				backgroundUrl: "/images/homepage/solutions-we-offer/GlobalAccounts.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Solutions de feuilles \n de carbone",
				backgroundUrl: "/images/homepage/solutions-we-offer/CarbonSheetSolutions.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Extrusions d'aluminium \n sur mesure",
				backgroundUrl: "/images/homepage/solutions-we-offer/CustomAluminumExtrusions.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
	es: {
		title1: "Nuestra oferta",
		title2: "de soluciones",
		title: "Nuestra oferta de soluciones",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Más soluciones de \n metal",
		description: `Sabemos que la necesidad de cada cliente es única. Aprovechamos más de 180 años de experiencia para brindar al cliente soluciones de metales personalizadas. Exploremos cuál solución es la más adecuada para sus necesidades.`,
		buttonLabel: "Hablemos",
		buttonUrl: "/contact-us#productQuestion",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Procesamiento \n avanzado",
				backgroundUrl: "/images/homepage/solutions-we-offer/AdvancedProcessing.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Cuentas \n globales",
				backgroundUrl: "/images/homepage/solutions-we-offer/GlobalAccounts.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Soluciones de láminas \n de carbono",
				backgroundUrl: "/images/homepage/solutions-we-offer/CarbonSheetSolutions.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Extrusiones de aluminio \n personalizadas",
				backgroundUrl: "/images/homepage/solutions-we-offer/CustomAluminumExtrusions.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},

	//{ text: "View \n All Industries \n We Serve", addButton: true },
};
