import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Button } from "@ryerson/frontend.button";
import { Typography } from "@ryerson/frontend.typography";
import { ContentSection } from "@ryerson/frontend.layout";
import { Media } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Link } from "@ryerson/frontend.navigation";

export type RyersonAcademyCard = {
	imageUrl: string;
	description: string;
};

export type RyersonAcademyContent = {
	title: string;
	description: string;
	actionUrl: string;
	actionLabel: string;
	cards: RyersonAcademyCard[];
};

export type RyersonAcademyProps = {
	content: RyersonAcademyContent;
};

const Divider = styled.hr`
	width: 100%;
	opacity: 0.1;
	margin-top: 45px;
	margin-bottom: 55px;
`;

const Description = styled.div`
	width: calc(100% - 360px);
	display: inline-block;
	vertical-align: middle;
	padding-right: 200px;
	box-sizing: border-box;
`;

const ButtonContainer = styled.div`
	width: 360px;
	display: inline-block;
	text-align: right;
	vertical-align: middle;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.lg}) {
				margin-bottom: 60px;
				width: 100%;
				display: block;
				margin-top: 20px;
			}
		`;
	}}
`;

const ImageContainer = styled.div`
	width: 100%;
	display: block;
	margin-top: 90px;
	box-sizing: border-box;
`;

const ImageText = styled.div`
	width: calc(33.3333% - 26.666666px);
	display: inline-block;
	margin-right: 40px;
	vertical-align: top;
	height: auto;
	&:nth-child(3) {
		margin-right: 0px;
	}
	img {
		width: 100%;
		height: auto;
		max-height: 245px;
		min-width: 369px;
		display: block;
		margin-bottom: 25px;
	}
	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.lg}) {
				width: 100%;
				display: block;
				margin-right: 0px;
				margin-bottom: 40px;
				img {
					margin-bottom: 20px;
				}
			}
		`;
	}}
`;

const RyersonAcademy: React.FC<RyersonAcademyProps> = ({ content }) => {
	const { theme } = useTheme();
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection vPadding="40px">
					<Typography variant="h2">{content.title}</Typography>
					<Divider
						css={css`
							color: ${theme.colors.primary.darkerGray};
						`}
					/>
					<Description>
						<Typography size="md">{content.description}</Typography>
					</Description>
					<ButtonContainer theme={theme}>
						<Link to={content.actionUrl} gatsby>
							<Button
								onClick={() => {}}
								label={content.actionLabel}
								size="xl"
								fullwidth={true}
							/>
						</Link>
					</ButtonContainer>
					<ImageContainer>
						{content.cards.map((card: RyersonAcademyCard, index: number) => {
							return (
								<ImageText theme={theme} key={index}>
									<img src={card.imageUrl} />
									<Typography size="md">{card.description}</Typography>
								</ImageText>
							);
						})}
					</ImageContainer>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<Typography
					css={css`
						margin-top: 28px;
						margin-bottom: 40px;
					`}
					size="md"
					variant="div"
				>
					{content.description}
				</Typography>
				{content.cards.map((card: RyersonAcademyCard, index: number) => {
					return (
						<ImageText theme={theme} key={index}>
							<img src={card.imageUrl} />
							<Typography size="md">{card.description}</Typography>
						</ImageText>
					);
				})}
				<ButtonContainer theme={theme}>
					<Link to={content.actionUrl} gatsby>
						<Button
							onClick={() => {}}
							label={content.actionLabel}
							size="xl"
							fullwidth={true}
						/>
					</Link>
				</ButtonContainer>
			</Media>
		</>
	);
};

export default RyersonAcademy;
