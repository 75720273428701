import React from "react";
import { Media } from "@ryerson/frontend.layout";
import { ContentSection } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Link } from "@ryerson/frontend.navigation";

export type AwardsCard = {
	imageUrl: string;
	url: string;
};

export type AwardsContent = {
	title: string;
	cards: AwardsCard[];
};

export type AwardsProps = {
	content: AwardsContent;
};

const CardContainer = styled.div`
	width: 100%;
	display: block;
	height: auto;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.lg}) {
				text-align: center;
			}
		`;
	}}
`;

const Card = styled.div`
	width: 160px;
	height: 160px;
	margin-right: 40px;
	display: inline-block;
	margin-bottom: 40px;
	vertical-align: top;
	overflow: hidden;
	background-repeat: no-repeat;
	background-position: center center;
	border-radius: 2px;
	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: ${theme.colors.primary.white};
			@media (max-width: ${theme.breakpoints.lg}) {
				width: 140px;
				height: 140px;
				margin-right: 10px;
				margin-left: 10px;
				margin-bottom: 0px;
				border: 1px solid ${theme.colors.primary.lighterGray};
				margin-top: 20px;
			}
			@media (max-width: 360px) {
				width: 140px;
				height: 140px;
				margin-top: 20px;
				margin-left: 0px;
				margin-right: 20px;
				&:nth-child(even) {
					margin-right: 0px;
				}
			}
			@media (max-width: 340px) {
				width: calc(50% - 10px);
				height: 130px;
				margin-top: 20px;
				margin-left: 0px;
				margin-right: 20px;
				&:nth-child(even) {
					margin-right: 0px;
				}
			}
		`;
	}}
`;

const Awards: React.FC<AwardsProps> = ({ content }) => {
	const { theme } = useTheme();

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection
					vPadding="0px"
					type="split"
					split={{
						splitDirection: "vertical",
						splitColors: [
							{
								color: theme.colors.tertiary.background,
								percentage: "60%",
							},
							{
								color: theme.colors.secondary.background,
								percentage: "40%",
							},
						],
					}}
				>
					<Typography
						type="tertiary"
						css={css`
							margin-top: 0px;
							margin-bottom: 30px;
						`}
						variant="h2"
					>
						{content.title}
					</Typography>
					<CardContainer theme={theme}>
						{content.cards.map((card: AwardsCard, index: number) => {
							return (
								<Link to={card.url} gatsby={false} target="_blank" key={index}>
									<Card
										theme={theme}
										css={css`
											background-image: url(${card.imageUrl});
											cursor: pointer;
										`}
									/>
								</Link>
							);
						})}
					</CardContainer>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="primary" hPadding="0px">
					<CardContainer theme={theme}>
						{content.cards.map((card: AwardsCard, index: number) => {
							return (
								<Link to={card.url} gatsby={false} target="_blank" key={index}>
									<Card
										theme={theme}
										key={index}
										css={css`
											background-image: url(${card.imageUrl});
										`}
									/>
								</Link>
							);
						})}
					</CardContainer>
				</ContentSection>
			</Media>
		</>
	);
};

export default Awards;
