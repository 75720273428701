import { ContactUsContent } from "@components/Shared/ContactUsRefactor/ContactUs";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const ContactUsCustomContent: LanguageContent<ContactUsContent> = {
	en: {
		title: "Interested in joining \n the Ryerson team?",
		titleSpecial: "\n Search current \n openings in your \n area.",
		navLabel: "Join Ryerson",
		imageUrl: "/images/ryerson-academy/JoinRyerson.jpg",
		button1: "",
		button2: "View Job \n Openings",
		button2Link: "/about-us/careers/open-positions/",
	},
	fr: {
		title: "Souhaitez-vous faire partie de \n l’équipe de Ryerson?",
		titleSpecial: "\n Rechercher les \n postes vacants actuels dans \n votre région.",
		navLabel: "Joignez-vous à Ryerson",
		imageUrl: "/images/ryerson-academy/JoinRyerson.jpg",
		button1: "",
		button2: "Afficher le travail \n Ouvertures",
		button2Link: "/about-us/careers/open-positions/",
	},
	es: {
		title: "¿Le interesa unirse al \n equipo de Ryerson?",
		titleSpecial: "\n Busque vacantes \n actuales en \n su región.",
		navLabel: "Únete a Ryerson",
		imageUrl: "/images/ryerson-academy/JoinRyerson.jpg",
		button1: "",
		button2: "Ver trabajo \n Aperturas",
		button2Link: "/about-us/careers/open-positions/",
	},
};
