import styled from "@emotion/styled";
import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { Container, ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { css } from "@emotion/react";
import rgba from "polished/lib/color/rgba";
import { OurExpertsProps } from "../OurExperts";

const AddressSection = styled(Flex)`
	border-radius: 2px;
	max-width: 540px;
	margin: 20px auto;
	${(props: any) => {
		const { theme } = props;
		return css`
			border: 1px solid ${rgba(theme.colors.primary.darkGray, 0.1)};
		`;
	}}
`;
const CallUsSection = styled.div`
	margin-top: 20px;
	${(props: any) => {
		const { theme } = props;
		return css`
			border-top: 1px solid ${rgba(theme.colors.primary.secondaryBrand, 0.1)};
		`;
	}}
`;
const ProfileContent = styled.div`
	margin-top: 20px;
`;
const LogoWrapper = styled(FlexItem)`
	@media (max-width: 500px) {
		width: 100px;
	}
`;
const logo = css`
	mix-blend-mode: exclusion;
	opacity: 1;
	width: 100%;
`;
const OfficeSection = styled(ContentSection)`
	border-radius: 2px;
	flex-grow: 1;
`;

const Profiles = styled.div`
	display: block;
	width: 100%;
	margin: 0 auto;
	max-width: 540px;
`;

const Profile = styled.div`
	display: inline-block;
	width: calc(50% - 10px);
	vertical-align: top;
	margin-right: 20px;
	max-width: 260px;
`;

const ProfileImage = styled.div`
	background-size: cover;
	background-position: top;
	width: 100%;
	overflow: hidden;
	margin: 0;
	img {
		max-width: 100%;
		height: auto;
		display: block;
	}
`;

const ProfileCard = (props: any) => {
	const { theme } = useTheme();

	const [portraitHeight, setPortraitHeigh] = React.useState<number>(0);

	React.useEffect(() => {
		if (window) {
			window.addEventListener("resize", () => {
				let width = window.innerWidth;
				let portraitWidth = (width - 60) / 2;
				if (portraitWidth <= 360) setPortraitHeigh(portraitWidth);
				else setPortraitHeigh(360);
			});
			let width = window.innerWidth;
			let portraitWidth = (width - 60) / 2;
			if (portraitWidth <= 360) setPortraitHeigh(portraitWidth);
			else setPortraitHeigh(360);
		}
	}, []);

	return (
		<Profile
			id={props.index}
			css={css`
				margin-right: ${props.index % 2 === 0 ? "20px" : "0px"};
			`}
		>
			<ProfileImage
				theme={theme}
				css={css`
					height: ${portraitHeight + "px"};
				`}
			>
				<img src={props.profile.imageUrl} />
			</ProfileImage>
			<ProfileContent>
				<Typography
					size="md"
					variant="div"
					weight="bold"
					color={theme.colors.primary.primaryBrand}
				>
					{props.profile.name}
				</Typography>
				<Typography color={theme.colors.primary.gray} variant="div" size="md">
					{props.profile.title}
				</Typography>
			</ProfileContent>
		</Profile>
	);
};

const CompanyCard = (props: any) => {
	const { theme } = useTheme();
	return (
		<OfficeSection theme={theme} type="secondary">
			<Flex justifyContent="space-between">
				<FlexItem>
					<Typography
						variant="p"
						css={css`
							margin-top: 0px;
						`}
						color={theme.colors.primary.secondaryBrand}
						weight="bold"
						size="xl"
					>
						{props.info.companyName}
					</Typography>
				</FlexItem>
				<LogoWrapper>
					<img src={props?.info.logoUrl} css={logo} />
				</LogoWrapper>
			</Flex>
			<Typography
				variant="p"
				size="md"
				css={css`
					margin-top: 5px;
				`}
			>
				{props.info.address1}
				<br /> {props.info.address2}
				<br /> {props.info.phone}
			</Typography>
			<CallUsSection theme={theme}>
				<Typography variant="div" size="md" type="secondary" css={{ marginTop: "20px" }}>
					{props.info.questionsLine}
				</Typography>
				<Typography
					variant="div"
					color={theme.colors.primary.secondaryBrand}
					weight="bold"
					size="lg"
				>
					{props.info.questionsPhone}
				</Typography>
			</CallUsSection>
		</OfficeSection>
	);
};

const OurExpertsMobile: React.FC<OurExpertsProps> = ({ ...props }) => {
	const { theme } = useTheme();
	const set1 = props.content.experts.filter((expert: any, index: number) => index < 2);
	const set2 = props.content.experts.filter((expert: any, index: number) => index > 1);

	return (
		<Container hPadding="0px">
			<Typography
				variant="div"
				css={css`
					margin-bottom: 15px;
				`}
				color={theme.colors.primary.gray}
				size="md"
			>
				{props.content.description}
			</Typography>
			<Profiles>
				{set1.map(function (expert: any, index: number) {
					return <ProfileCard profile={expert} key={index} index={index} />;
				})}
			</Profiles>
			<AddressSection direction="column" justifyContent="space-between" theme={theme}>
				<CompanyCard info={props.content} />
			</AddressSection>

			<Profiles>
				{set2.map(function (expert: any, index: number) {
					return <ProfileCard profile={expert} key={index} index={index} />;
				})}
			</Profiles>
		</Container>
	);
};

export default OurExpertsMobile;
