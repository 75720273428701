import React from "react";
import SolutionsWeOfferDesktop from "./Desktop/SolutionsWeOfferDesktop";
import SolutionsWeOfferMobile from "./Mobile/SolutionsWeOfferMobile";
import { IconList } from "@ryerson/frontend.assets";
import { Media } from "@ryerson/frontend.layout";
import { SolutionsContent } from "./Content";
import { ParallaxProvider } from "react-scroll-parallax";
import { useApplication } from "@ryerson/frontend.application";

export type CardsContent = {
	icon: keyof IconList;
	text: string;
	backgroundUrl: string;
	link?: string;
};

export type SolutionsWeOfferContent = {
	title1: string;
	title2: string;
	description: string;
	buttonLabel: string;
	cards: CardsContent[];
	title: string;
	actionLabel: string;
	actionUrl: string;
	buttonUrl?: string;
};

export interface SolutionsWeOfferProps {
	content?: SolutionsWeOfferContent;
	background?: "primary" | "secondary" | "tertiary";
	bottomBackground?: "primary" | "secondary" | "tertiary";
}

export interface SolutionsWeOfferComponentProps {
	content: SolutionsWeOfferContent;
	background: "primary" | "secondary" | "tertiary";
	bottomBackground: "primary" | "secondary" | "tertiary";
}

const SolutionsWeOffer: React.FC<SolutionsWeOfferProps> = ({
	background = "tertiary",
	content,
	bottomBackground = "primary",
}) => {
	const {
		localization: { language },
	} = useApplication();
	if (!content) {
		content = SolutionsContent[language];
	}
	return (
		<>
			<Media lessThan="lg">
				<SolutionsWeOfferMobile
					content={content}
					background={background}
					bottomBackground={bottomBackground}
				/>
			</Media>
			<Media greaterThanOrEqual="lg">
				<ParallaxProvider>
					<SolutionsWeOfferDesktop
						content={content}
						background={background}
						bottomBackground={bottomBackground}
					/>
				</ParallaxProvider>
			</Media>
		</>
	);
};

export default SolutionsWeOffer;
