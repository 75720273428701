import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Typography } from "@ryerson/frontend.typography";
import { Video } from "@ryerson/frontend.media";
import { ContentSection } from "@ryerson/frontend.layout";
import { Carousel } from "@ryerson/frontend.carousel";
import MultiImageSlide from "../MultiImageSlide/MultiImageSlide";
import { CompanyComponentProps } from "../Company";
import { ButtonLink } from "@ryerson/frontend.navigation";
import { useApplication } from "@ryerson/frontend.application";
import { VideoWrapperProps } from "@components/Helper/common";
import { getVideoPadding } from "@components/Helper/commonStyles";

const VideoWrapper = styled.div`
	${(props: VideoWrapperProps) => {
		const { buttonOrientation = "top", buttonSize = "md" } = props;
		return css`
			height: 300px;
			${buttonOrientation === "center"
				? 0
				: getVideoPadding(buttonOrientation, true, buttonSize)};
		`;
	}}
`;

const SectionWrapper = styled.div`
	margin-bottom: 65px;
	margin-top: 20px;
`;

const CompanyMobile: React.FC<CompanyComponentProps> = ({ cardType, content }) => {
	const {
		localization: { language },
	} = useApplication();

	const imageStyles = css`
		width: 100%;
	`;

	const logoStyles = css`
		mix-blend-mode: exclusion;
		display: block;
		vertical-align: middle;
		max-width: 60%;
		max-height: 100px;
		margin: 25px 0px;
	`;

	const carouselStyles = css`
		width: 100%;
	`;

	switch (cardType) {
		case "mediaLeft":
			return (
				<ContentSection>
					<SectionWrapper>
						<Typography variant="h3">{content.title}</Typography>
						{content.description.map((text: any, index: number) => {
							return (
								<Typography key={index} variant="p" size="md">
									{text}
								</Typography>
							);
						})}
						{content.hasVideo ? (
							<VideoWrapper>
								<Video
									videoId={content.videoUrl ?? ""}
									buttonOrientation="top"
								></Video>
							</VideoWrapper>
						) : !content.imageUrl ? (
							<></>
						) : (
							<img src={content.imageUrl[0]} css={imageStyles} />
						)}
						<img src={content.logoImageUrl} css={logoStyles} />
						<ButtonLink
							to={content.linkUrl}
							linkType={content.linkExternal ? "external" : "internal"}
							label={content.linkText}
						/>
					</SectionWrapper>
				</ContentSection>
			);
		case "mediaRight":
			return (
				<ContentSection>
					<SectionWrapper>
						<Typography variant="h3">{content.title}</Typography>
						{content.description.map((text: any, index: number) => {
							return (
								<Typography key={index} variant="p" size="md">
									{text}
								</Typography>
							);
						})}
						{content.hasVideo ? (
							<VideoWrapper>
								<Video
									videoId={content.videoUrl ?? ""}
									buttonOrientation="top"
								></Video>
							</VideoWrapper>
						) : !content.imageUrl ? (
							<></>
						) : (
							<img src={content.imageUrl[0]} css={imageStyles} />
						)}
						<img src={content.logoImageUrl} css={logoStyles} />
						<ButtonLink
							to={content.linkUrl}
							linkType={content.linkExternal ? "external" : "internal"}
							label={content.linkText}
						/>
					</SectionWrapper>
				</ContentSection>
			);
		case "multiImage":
			return (
				<ContentSection>
					<SectionWrapper>
						<Typography variant="h3">{content.title}</Typography>
						{content.description.map((text: any, index: number) => {
							return (
								<Typography key={index} variant="p" size="md">
									{text}
								</Typography>
							);
						})}
						{Array.isArray(content.imageUrl) ? (
							<Carousel header={false} css={carouselStyles} language={language}>
								{content.imageUrl.map((image: any, index: number) => (
									<MultiImageSlide
										key={index}
										imageUrl={image}
										imageOverlayText={
											Array.isArray(content.imageStrings)
												? content.imageStrings[index]
												: ""
										}
									/>
								))}
							</Carousel>
						) : (
							<></>
						)}
						<img src={content.logoImageUrl} css={logoStyles} />
						<ButtonLink
							to={content.linkUrl}
							linkType={content.linkExternal ? "external" : "internal"}
							label={content.linkText}
						/>
					</SectionWrapper>
				</ContentSection>
			);
		default:
			return null;
	}
};

/*
const CompanyMobile = ({ ...props }) => {
	const { theme } = useTheme();

	return (
		<>
			<ContentSection>
				{props.companies.map((company: any, index: number) => {
					return <CompanySection {...company} theme={theme}></CompanySection>;
				})}
			</ContentSection>
		</>
	);
};
*/
export default CompanyMobile;
