import React from "react";
import styled from "@emotion/styled";
import { Media } from "@ryerson/frontend.layout";
import { Flex, FlexItem } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { Button } from "@ryerson/frontend.button";
import { Link } from "@ryerson/frontend.navigation";

export type ButtonSize = "sm" | "md" | "lg";
export type Foreground = "light" | "dark";

interface ContactCardProps {
	color?: string;
	description?: string;
	textColor?: string;
	buttonColor?: string;
	foreground?: Foreground;
	buttonSize?: ButtonSize;
	withImage?: boolean;
	oneButtonVariant?: boolean;
	buttonLink?: string;
}

const ContactCard: React.FC<ContactCardProps> = ({
	color,
	description = "",
	textColor,
	buttonColor,
	foreground = "light",
	buttonSize = "sm",
	withImage = true,
	oneButtonVariant = false,
	buttonLink = "",
}) => {
	const { theme } = useTheme();

	if (!color) {
		color = `${theme.colors.primary.white}`;
	}

	if (!textColor) {
		textColor = `${theme.colors.primary.darkerGray}`;
	}

	if (!buttonColor) {
		buttonColor = `${theme.colors.primary.primaryBrand}`;
	}

	const Card = styled(Flex)`
		background-color: ${color};
		border-radius: 2px;
		min-height: 190px;
		cursor: pointer;
		padding: 40px;

		&:nth-of-type(2) {
			margin-top: ${withImage ? "40px" : "0px"};
		}

		@media only screen and (max-width: ${theme.breakpoints.lg}) {
			min-height: ${oneButtonVariant ? "71px" : "120px"};
			max-height: 120px;
			&:nth-of-type(2) {
				margin-top: ${withImage ? "20px" : "0px"};
			}
			padding: 20px;
		}
	`;

	return (
		<>
			<Media greaterThanOrEqual="lg">
				{buttonLink && buttonLink.length > 0 ? (
					<Link to={buttonLink}>
						<Card direction="column" justifyContent="space-between">
							<FlexItem css={{ width: "115px" }}>
								<Typography
									css={{ margin: "0" }}
									variant="p"
									size="md"
									weight="bold"
									color={textColor}
								>
									{description}
								</Typography>
							</FlexItem>
							<Flex justifyContent="flex-end">
								<Button
									shape="circle"
									label=""
									rightIcon="forward"
									size={buttonSize}
									onClick={() => {}}
									background={buttonColor}
									foreground={foreground}
									dataTestId="button"
								/>
							</Flex>
						</Card>
					</Link>
				) : (
					<Card
						onClick={() => {
							if (window && (window as any).LiveChatWidget)
								(window as any).LiveChatWidget.call("maximize");
						}}
						direction="column"
						justifyContent="space-between"
						data-test-id="contact-us-chat-with-metal-expert-card"
					>
						<FlexItem css={{ width: "115px" }}>
							<Typography
								css={{ margin: "0" }}
								variant="p"
								weight="bold"
								size="md"
								color={textColor}
							>
								{description}
							</Typography>
						</FlexItem>
						<Flex justifyContent="flex-end">
							<Button
								shape="circle"
								label=""
								rightIcon="forward"
								size={buttonSize}
								onClick={() => {
									if (window && (window as any).LiveChatWidget)
										(window as any).LiveChatWidget.call("maximize");
								}}
								background={buttonColor}
								foreground={foreground}
								dataTestId="chat-with-metal-expert-button"
							/>
						</Flex>
					</Card>
				)}
			</Media>
			<Media lessThan="lg">
				{buttonLink && buttonLink.length > 0 ? (
					<Link to={buttonLink}>
						<Card
							onClick={() => {}}
							direction={oneButtonVariant ? "row" : "column"}
							justifyContent="space-between"
							data-test-id="contact-us-card"
						>
							<FlexItem css={{ width: "115px" }}>
								<Typography
									css={{ margin: "0" }}
									variant="p"
									size="md"
									weight="bold"
									color={textColor}
								>
									{description}
								</Typography>
							</FlexItem>
							<Flex
								direction={oneButtonVariant ? "column" : "row"}
								justifyContent={oneButtonVariant ? "space-around" : "flex-end"}
							>
								<Button
									shape="circle"
									label=""
									rightIcon="forward"
									size={buttonSize}
									onClick={() => {}}
									background={buttonColor}
									foreground={foreground}
									dataTestId="button"
								/>
							</Flex>
						</Card>
					</Link>
				) : (
					<Card
						onClick={() => {}}
						direction={oneButtonVariant ? "row" : "column"}
						justifyContent="space-between"
						data-test-id="contact-us-card"
					>
						<FlexItem css={{ width: "115px" }}>
							<Typography
								css={{ margin: "0" }}
								variant="p"
								size="md"
								weight="bold"
								color={textColor}
							>
								{description}
							</Typography>
						</FlexItem>
						<Flex
							direction={oneButtonVariant ? "column" : "row"}
							justifyContent={oneButtonVariant ? "space-around" : "flex-end"}
						>
							<Button
								shape="circle"
								label=""
								rightIcon="forward"
								size={buttonSize}
								onClick={() => {
									if (window && (window as any).LiveChatWidget)
										(window as any).LiveChatWidget.call("maximize");
								}}
								background={buttonColor}
								foreground={foreground}
								dataTestId="button"
							/>
						</Flex>
					</Card>
				)}
			</Media>
		</>
	);
};

export default ContactCard;
