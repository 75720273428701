import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import RyersonDifferenceSection from "@components/Shared/RyersonDifference/RyersonDifferenceSection";
import { RyersonContent } from "@components/Shared/RyersonDifference/Content2";
import WhatOurCustomersCustom from "@components/Shared/WhatOurCustomers/WhatOurCustomersCustom";
import { WhatOurContentSays } from "@components/Shared/WhatOurCustomers/RefactorContent";
import MetalExperts from "@components/Shared/YourMetalExperts/YourMetalExperts";
import { YourStaticContent } from "@components/Shared/YourMetalExperts/Content";
import { AllSections, Sections } from "@components/Shared/AllSections/AllSections";

import FrequentlyAskedQuestionsSection from "@components/Shared/FrequentlyAskedQuestions/FrequentlyAskedQuestionsSection";
import ContactUsRefactor from "@components/Shared/ContactUsRefactor/ContactUs";
import { DefaultContactUsContent } from "@components/Shared/ContactUsRefactor/Content";
import SubNavigation, {
	NavigableItem,
} from "@components/BarTubeStructuralProcessing/SubNavigation/SubNavigation";
import { Media } from "@ryerson/frontend.layout";
import ExploreOtherCapabilitiesSolutions from "../ExploreOtherCapabilitiesSolutions/ExploreOtherCapabilitiesSolutions";
import { ExploreOtherCapabilitiesSolutionsContent } from "../ExploreOtherCapabilitiesSolutions/Content";
import TextImage, {
	TextImageSlide,
} from "@components/BarTubeStructuralProcessing/TextImageSlide/TextImageSlide";
import Hero, { HeroProps } from "@components/BarTubeStructuralProcessing/Hero/Hero";
import SubPageNavigation, {
	SubPageCards,
} from "@components/BarTubeStructuralProcessing/SubPageNavigation/SubPageNavigation";
import { useApplication } from "@ryerson/frontend.application";
import SolutionsWeOffer, {
	SolutionsWeOfferContent,
} from "@components/Solutions/ParallaxSolutionsWeOffer/SolutionsWeOffer";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { FAQContent } from "@components/Shared/FrequentlyAskedQuestions/Content";
import MetalInsightsAndResourcesRefactor, {
	defaultStaticContent as MetalInsightsContent,
} from "@components/Shared/MetalInsights/MetalInsightsAndResourcesRefactor";

const WhoDoWeHelpContent: LanguageContent<SolutionsWeOfferContent> = {
	en: {
		title1: "Who Do We Help?",
		title2: " ",
		title: "Who Do We Help",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Learn More",
		description: `Companies producing world-class products have one critical need: the right metal at the right moment. At Ryerson, we don’t take that responsibility lightly. It’s why thousands of companies across hundreds of markets turn to us for their metal needs.`,
		buttonLabel: "Lets Talk!",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Advanced \n Processing",
				backgroundUrl:
					"/images/solutions-we-offer/AdvancedProcessingUpdate.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Global \n Accounts",
				backgroundUrl: "/images/solutions-we-offer/GlobalAccountsUpdate2.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Carbon Sheet \n Solutions",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Custom Aluminum \n Extrusions",
				backgroundUrl: "/images/solutions-we-offer/SolutionsFour.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
	fr: {
		title1: "Qui aidons-nous?",
		title2: "",
		title: "Qui aidons-nous",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Apprendre encore plus",
		description: `Les entreprises produisant des produits de classe mondiale ont un besoin essentiel : le bon métal au bon moment. Chez Ryerson, nous ne prenons pas cette responsabilité à la légère. C’est pourquoi des milliers d’entreprises provenant de centaines de marchés font appel à nous lorsqu’ils ont besoin de produits métallurgiques.`,
		buttonLabel: "Discutons",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Traitement \n avancé",
				backgroundUrl:
					"/images/solutions-we-offer/AdvancedProcessingUpdate.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Comptes \n internationaux",
				backgroundUrl: "/images/solutions-we-offer/GlobalAccountsUpdate2.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Solutions de feuilles \n de carbone",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Profilés d'aluminium \n personnalisés",
				backgroundUrl: "/images/solutions-we-offer/SolutionsFour.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
	es: {
		title1: "¿A quién ayudamos?",
		title2: "",
		title: "¿A quién ayudamos",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Aprende más",
		description: `A compañías que fabrican productos de clase mundial que tienen una necesidad crítica: el metal correcto en el momento correcto. En Ryerson no nos tomamos esa responsabilidad a la ligera. Por eso, miles de compañías de cientos de mercados acuden a nosotros para cubrir sus necesidades de metal.`,
		buttonLabel: "Hablemos",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Procesamiento \n avanzado",
				backgroundUrl:
					"/images/solutions-we-offer/AdvancedProcessingUpdate.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Cuentas \n globales",
				backgroundUrl: "/images/solutions-we-offer/GlobalAccountsUpdate2.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Soluciones de láminas \n de carbono",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Extrusiones de aluminio \n personalizadas",
				backgroundUrl: "/images/solutions-we-offer/SolutionsFour.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
};

type BasePageContent = {
	heroSection: any;
	subNavigation?: any;
	subPages?: any;
	equipmentAndProcess?: any;
	whoDoWeHelp?: any;
	frequentlyAskedQuestions?: any;
	relatedBlogs?: any;
	exploreOtherCapabilitiesAndSolutions?: any;
};

export interface BasePageProps {
	content: BasePageContent;
}

export default (props: BasePageProps) => {
	const content = props.content;
	const heroProps = content.heroSection as HeroProps;

	const {
		localization: { language },
	} = useApplication();

	const subNav: NavigableItem[] = [];

	const sections: Sections[] = [];
	if (content.subPages) {
		let subPageCardProps = content.subPages as SubPageCards;
		sections.push({
			title: content.subPages.title,
			cmp: <SubPageNavigation {...subPageCardProps} />,
		});
	}
	if (content.equipmentAndProcess) {
		subNav.push({
			id: content.equipmentAndProcess.title.toLowerCase().replace(/\s+/g, "-"),
			title: content.equipmentAndProcess.navLabel,
		});
		const textImageSlideContent = content.equipmentAndProcess as TextImageSlide;
		sections.push({
			title: content.equipmentAndProcess.title,
			cmp: <TextImage {...textImageSlideContent} />,
		});
	}
	sections.push({
		title: RyersonContent[language].title1 + " " + RyersonContent[language].title2,
		cmp: <RyersonDifferenceSection />,
	});
	subNav.push({
		id: (RyersonContent[language].title1 + " " + RyersonContent[language].title2)
			.toLowerCase()
			.replace(/\s+/g, "-"),
		title: RyersonContent[language].navLabel ?? "",
	});
	subNav.push({
		id: WhoDoWeHelpContent[language].title.toLowerCase().replace(/\s+/g, "-"),
		title: WhoDoWeHelpContent[language].title1,
	});
	sections.push({
		title: WhoDoWeHelpContent[language].title,
		cmp: <SolutionsWeOffer background="tertiary" content={WhoDoWeHelpContent[language]} />,
	});
	sections.push({
		title: WhatOurContentSays[language].title,
		cmp: <WhatOurCustomersCustom />,
	});
	subNav.push({
		id: WhatOurContentSays[language].title.toLowerCase().replace(/\s+/g, "-"),
		title: WhatOurContentSays[language].navLabel,
	});
	sections.push({
		title: FAQContent[language].title1 + " " + FAQContent[language].title2,
		cmp: <FrequentlyAskedQuestionsSection />,
	});
	subNav.push({
		id: (FAQContent[language].title1 + " " + FAQContent[language].title2)
			.toLowerCase()
			.replace(/\s+/g, "-"),
		title: FAQContent[language].navLabel,
	});
	sections.push({ title: YourStaticContent[language].navLabel, cmp: <MetalExperts /> });
	subNav.push({
		id: YourStaticContent[language].navLabel.toLowerCase().replace(/\s+/g, "-"),
		title: YourStaticContent[language].navLabel,
	});
	sections.push({
		title:
			MetalInsightsContent[language].title1 +
			" " +
			MetalInsightsContent[language].titleSeparator +
			" " +
			MetalInsightsContent[language].title2,
		cmp: <MetalInsightsAndResourcesRefactor />,
	});
	let metalInsightsTitle =
		MetalInsightsContent[language].title1 +
		" " +
		MetalInsightsContent[language].titleSeparator +
		" " +
		MetalInsightsContent[language].title2;
	subNav.push({
		id: metalInsightsTitle.toLowerCase().replace(/\s+/g, "-"),
		title: metalInsightsTitle,
	});
	if (content.exploreOtherCapabilitiesAndSolutions) {
		sections.push({
			title: content.exploreOtherCapabilitiesAndSolutions.title,
			cmp: (
				<ExploreOtherCapabilitiesSolutions
					{...content.exploreOtherCapabilitiesAndSolutions}
				/>
			),
		});
		subNav.push({
			id: content.exploreOtherCapabilitiesAndSolutions.title
				.toLowerCase()
				.replace(/\s+/g, "-"),
			title: content.exploreOtherCapabilitiesAndSolutions.navLabel,
		});
	} else {
		sections.push({
			title: ExploreOtherCapabilitiesSolutionsContent.title,
			cmp: (
				<ExploreOtherCapabilitiesSolutions {...ExploreOtherCapabilitiesSolutionsContent} />
			),
		});
		subNav.push({
			id: ExploreOtherCapabilitiesSolutionsContent.title.toLowerCase().replace(/\s+/g, "-"),
			title: ExploreOtherCapabilitiesSolutionsContent.title,
		});
	}
	sections.push({
		title: DefaultContactUsContent[language].title,
		cmp: <ContactUsRefactor oneButtonVariant={false} />,
		hide: true,
	});
	return (
		<Layout>
			<Hero {...heroProps} />
			<Media greaterThanOrEqual="lg">
				<SubNavigation navigableItems={subNav} />
			</Media>
			<AllSections sections={sections} />
			<Media lessThan="lg">
				<ContactUsRefactor oneButtonVariant={false} background="secondary" />
			</Media>
		</Layout>
	);
};
