import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Media } from "@ryerson/frontend.layout";
import { ContactUsComponentProps } from "../ContactUs";
import ContactCard from "../Shared/ContactCard";
import { Typography } from "@ryerson/frontend.typography";

const TextWrapper = styled.div`
	position: absolute;
	transform: translate(0px, -175px);
	white-space: pre-line;
`;

const ImageContainer = styled.div`
	flex-grow: 1;
	padding-right: 40px;
	text-align: right;
	img {
		display: block;
		margin-left: auto;
		margin-right: 0px;
	}
`;

const CardContainer = styled(Flex)`
	width: 250px;
`;

const CardItem = styled(FlexItem)`
	width: 260px;
`;

const Wrapper = styled(Flex)`
	margin-top: 40px;
`;

const ContactUsDesktop: React.FC<ContactUsComponentProps> = ({
	withImage,
	content,
	background,
	oneButtonVariant,
}) => {
	const { theme } = useTheme();
	const { title, titleSpecial, button1, button2, imageUrl, button1Link, button2Link } = content;

	return (
		<Media greaterThanOrEqual="lg">
			{withImage ? (
				<ContentSection vPadding="100px" type={background}>
					<div css={{ height: "100px" }}></div>
					<TextWrapper>
						<Typography variant="h1" css={{ marginTop: "35px" }}>
							{title}&nbsp;
							<Typography font="inherit">{titleSpecial}</Typography>
						</Typography>
					</TextWrapper>
					<Flex>
						<ImageContainer>
							<img src={imageUrl} />
						</ImageContainer>
						<CardContainer
							justifyContent={oneButtonVariant ? "flex-end" : "space-between"}
							direction="column"
						>
							{oneButtonVariant === false ? (
								<FlexItem>
									<ContactCard
										color={
											process.env.GATSBY_COMPANY === "southernToolSteel"
												? theme.colors.primary.primaryBrand
												: theme.colors.primary.accentBrand
										}
										description={button1}
										textColor={theme.colors.primary.secondaryBrand}
										buttonColor={theme.colors.primary.secondaryBrand}
										buttonLink={button1Link}
									/>
								</FlexItem>
							) : (
								<></>
							)}
							<FlexItem>
								<ContactCard
									color={theme.colors.primary.secondaryBrand}
									description={button2}
									textColor={theme.colors.primary.white}
									buttonColor={
										process.env.GATSBY_COMPANY === "southernToolSteel"
											? theme.colors.primary.primaryBrand
											: theme.colors.primary.accentBrand
									}
									foreground="dark"
									buttonLink={button2Link}
								/>
							</FlexItem>
						</CardContainer>
					</Flex>
				</ContentSection>
			) : (
				<ContentSection vPadding="45px" type={background}>
					<Flex justifyContent="space-between">
						<FlexItem
							css={css`
								white-space: pre-line;
							`}
						>
							<Typography
								variant="h2"
								css={css`
									margin-top: 40px;
								`}
							>
								{title}&nbsp;
								<Typography font="inherit">{titleSpecial}</Typography>
							</Typography>
						</FlexItem>
						<Wrapper>
							<CardItem css={{ marginRight: "40px" }}>
								<ContactCard
									color={theme.colors.primary.secondaryBrand}
									description={button2}
									textColor={theme.colors.primary.white}
									buttonColor={
										process.env.GATSBY_COMPANY === "southernToolSteel"
											? theme.colors.primary.primaryBrand
											: theme.colors.primary.accentBrand
									}
									foreground="dark"
									withImage={withImage}
									buttonLink={button2Link}
								/>
							</CardItem>
							{oneButtonVariant === false ? (
								<CardItem>
									<ContactCard
										color={
											process.env.GATSBY_COMPANY === "southernToolSteel"
												? theme.colors.primary.primaryBrand
												: theme.colors.primary.accentBrand
										}
										description={button1}
										textColor={theme.colors.primary.secondaryBrand}
										buttonColor={theme.colors.primary.secondaryBrand}
										foreground="light"
										withImage={withImage}
										buttonLink={button1Link}
									/>
								</CardItem>
							) : (
								<></>
							)}
						</Wrapper>
					</Flex>
				</ContentSection>
			)}
		</Media>
	);
};

export default ContactUsDesktop;
