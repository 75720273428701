import React from "react";
import { AllSections, Sections } from "@components/Shared/AllSections/AllSections";
import Layout from "@components/Shared/Templates/Layout";
import { useApplication } from "@ryerson/frontend.application";
import TrainingAndDevelopmentHero from "@components/TrainingAndDevelopment/Hero/Hero";
import RyersonAcademyComponent from "@components/TrainingAndDevelopment/RyersonAcademy/RyersonAcademy";
import PaidInternshipsComponent from "@components/TrainingAndDevelopment/PaidInternship/PaidInternship";
import { ContactUsCustomContent } from "@components/Careers/ContactUs/Content";
import ContactUsMobile from "@components/Shared/ContactUsRefactor/Mobile/ContactUs";
import ContactUsDesktop from "@components/Shared/ContactUsRefactor/Desktop/ContactUs";
import { Media } from "@ryerson/frontend.layout";
import InOurDNA from "@components/Shared/InOurDNA/InOurDNA";
import EqualOpportunity from "@components/Shared/DiversityAndInclusion/EqualOpportunity/EqualOpportunity";
import Meta from "@components/Shared/model/MetaHelmet";
import {
	MetaObject,
	HeroContent,
	RyersonAcademy,
	PaidInternships,
	EqualOpportunityEmployerContent,
	InOurDNAContent,
} from "@content/ryerson/about-us/careers/working-at-ryerson/diversity-and-inclusion";

export default () => {
	const {
		localization: { language },
	} = useApplication();

	const sections: Sections[] = [];
	sections.push({
		title: RyersonAcademy[language].title,
		cmp: <RyersonAcademyComponent content={RyersonAcademy[language]} />,
	});
	sections.push({
		title: PaidInternships[language].title,
		cmp: <PaidInternshipsComponent content={PaidInternships[language]} />,
	});
	sections.push({
		title: EqualOpportunityEmployerContent[language].title,
		cmp: <EqualOpportunity content={EqualOpportunityEmployerContent[language]} />,
	});

	const ContactUsSetup = () => {
		return (
			<>
				<Media lessThan="lg">
					<ContactUsMobile
						content={ContactUsCustomContent[language]}
						withImage={false}
						background="secondary"
						oneButtonVariant={true}
					/>
				</Media>
				<Media greaterThanOrEqual="lg">
					<ContactUsDesktop
						content={ContactUsCustomContent[language]}
						withImage={true}
						background="primary"
						oneButtonVariant={true}
					/>
				</Media>
			</>
		);
	};

	sections.push({
		title: InOurDNAContent[language].title,
		cmp: <InOurDNA content={InOurDNAContent[language]} />,
	});
	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<TrainingAndDevelopmentHero content={HeroContent[language]} />
			<AllSections sections={sections} />
			<ContactUsSetup />
		</Layout>
	);
};
