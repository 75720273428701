import React from "react";
import { Media } from "@ryerson/frontend.layout";
import ProductsAndServicesDesktop from "./Desktop/ProductsAndServicesDesktop";
import ProductsAndServicesMobile from "./Mobile/ProductsAndServicesMobile";

export type ProductsAndServicesCards = {
	title: string;
	imageUrl: string;
	column_1: string[];
	column_2?: string[];
};

export type ProductsAndServicesContent = {
	title: string;
	cards: ProductsAndServicesCards[];
};

export type ProductsAndServicesProps = {
	content: ProductsAndServicesContent;
};

const ProductsAndServices: React.FC<ProductsAndServicesProps> = ({ content }) => {
	return (
		<>
			<Media lessThan="lg">
				<ProductsAndServicesMobile content={content} />
			</Media>
			<Media greaterThanOrEqual="lg">
				<ProductsAndServicesDesktop content={content} />
			</Media>
		</>
	);
};

export default ProductsAndServices;
