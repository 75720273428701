import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { css } from "@emotion/react";
import rgba from "polished/lib/color/rgba";
import styled from "@emotion/styled";
import { ProductsAndServicesProps } from "../ProductsAndServices";
import { Grid, Row, Column } from "@ryerson/frontend.layout";

const MainSection = styled(ContentSection)`
	padding-top: 100px;
`;
const HeaderSection = styled.div`
	${(props: any) => {
		const { theme } = props;
		return css`
			border-bottom: 1px solid ${rgba(theme.colors.primary.secondaryBrand, 0.1)};
		`;
	}}
`;
const CardWrapper = styled(Flex)`
	height: 400px;
	margin-top: 60px;
`;
const MainCard = styled(FlexItem)`
	position: relative;
	width: 30%;
	max-width: 360px;
	height: 302px;
	@media (max-width: 1100px) {
		height: 350px;
	}
	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: ${theme.colors.primary.white};
		`;
	}}
`;
const CardTitle = styled.div`
	width: 150px;
	display: block;
`;
const CardList = styled.div`
	position: absolute;
	width: calc(100% - 80px);
	display: block;
	font-size: 14px;
	line-height: 22px;
	@media (min-width: 1070px) {
		padding-left: 10px;
	}
`;
const ImageWrapper = styled.div`
	margin-top: 15px;
`;
const UL = styled.ul`
	@media (max-width: 1070px) {
		padding-left: 30px;
	}
	${(props: any) => {
		const { theme } = props;
		return css`
			li::marker {
				color: ${theme.colors.primary.text};
			}
		`;
	}}
`;

const List = (props: any) => {
	const { theme } = useTheme();
	return (
		<UL theme={theme}>
			{props.list.map((item: string, index: number) => {
				return (
					<li key={index}>
						<Typography color={theme.colors.primary.text} variant="span">
							{item}
						</Typography>
					</li>
				);
			})}
		</UL>
	);
};

const Card = (props: any) => {
	const { theme } = useTheme();
	return (
		<MainCard {...props}>
			<ContentSection>
				<Flex justifyContent="space-evenly">
					<FlexItem>
						<CardTitle>
							<Typography
								variant="p"
								size="lg"
								color={theme.colors.primary.header}
								weight="bolder"
							>
								{props.title}
							</Typography>
						</CardTitle>
					</FlexItem>
					<FlexItem>
						<ImageWrapper theme={theme}>
							<img src={props.imageUrl} />
						</ImageWrapper>
					</FlexItem>
				</Flex>
				<CardList>
					<Grid>
						{props.column_2 ? (
							<Row>
								<Column lg={6}>
									{" "}
									<List list={props.column_1} />{" "}
								</Column>
								<Column lg={6}>
									{" "}
									<List list={props.column_2} />{" "}
								</Column>
							</Row>
						) : (
							<Row>
								<Column lg={12}>
									{" "}
									<List list={props.column_1} />{" "}
								</Column>
							</Row>
						)}
					</Grid>
				</CardList>
			</ContentSection>
		</MainCard>
	);
};

const ProductsAndServicesDesktop: React.FC<ProductsAndServicesProps> = (props) => {
	const { theme } = useTheme();
	return (
		<MainSection type="secondary">
			<HeaderSection theme={theme}>
				<Typography
					variant="h1"
					css={css`
						letter-spacing: -4px;
						margin-bottom: 50px;
					`}
				>
					{props.content.title}
				</Typography>
			</HeaderSection>
			<CardWrapper justifyContent="space-between" wrap="nowrap">
				{props.content.cards.map(function (content: any, index: number) {
					return <Card key={index} theme={theme} {...content} />;
				})}
			</CardWrapper>
		</MainSection>
	);
};
export default ProductsAndServicesDesktop;
