import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Grid, Row, Column } from "@ryerson/frontend.layout";
import { Media } from "@ryerson/frontend.layout";
import { Breadcrumb } from "@ryerson/frontend.navigation";
import { Typography } from "@ryerson/frontend.typography";

export type TurretHeroProps = {
	content: TurretHeroContent;
};

export type TurretHeroContent = {
	title: string;
	subTitle: string;
	description: string[];
	logoUrl: string;
	imageUrl: string;
};

const BreadcrumbContainer = styled.div`
	margin-top: 32px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-top: 8px;
				margin-bottom: 17px;
			}
		`;
	}}
`;

const Divider = styled.div`
	width: 100%;
	opacity: 0.1;
	margin-top: 30px;
	margin-bottom: 42px;
	${(props: any) => {
		const { theme } = props;
		return css`
			border-bottom: 1px solid ${theme.colors.primary.white};
		`;
	}}
`;

const TextWrapper = styled.div`
	max-width: 460px;
`;

const ImageWrapper = styled.div`
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (min-width: ${theme.breakpoints.lg}) {
				max-width: 560px;
				height: 360px;
				margin: 0 auto;
				margin-right: 0;
				margin-top: 25px;
			}
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				height: 192px;
			}
		`;
	}}
`;

const LogoWrapper = styled.div`
	margin-top: 35px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-top: 0px;
				width: 70px;
				margin-top: 35px;
			}
		`;
	}}
`;

const TurretHero: React.FC<TurretHeroProps> = ({ content }) => {
	const { theme } = useTheme();

	const imageStyles = css`
		width: 100%;
		@media only screen and (max-width: ${theme.breakpoints.lg}) {
			height: 100%;
			object-fit: cover;
		}
	`;

	const logoStyles = css`
		mix-blend-mode: lighten;
		opacity: 0.8;
		width: 100%;
	`;

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="tertiary">
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<Flex justifyContent="space-between">
						<FlexItem>
							<Typography
								variant="h1"
								color={theme.colors.primary.white}
								css={css`
									font-size: 75px;
									margin-top: 27px;
								`}
							>
								{content.title}
							</Typography>
						</FlexItem>
						<FlexItem>
							<LogoWrapper theme={theme}>
								<img src={content.logoUrl} css={logoStyles} />
							</LogoWrapper>
						</FlexItem>
					</Flex>
					<Divider theme={theme} />
					<Grid>
						<Row>
							<Column lg={6}>
								<TextWrapper>
									<Typography variant="p" type="tertiary" size="lg">
										{content.subTitle}
									</Typography>
									{content.description.map((string: string, index: number) => {
										return (
											<Typography
												variant="p"
												type="tertiary"
												size="md"
												key={index}
											>
												{string}
											</Typography>
										);
									})}
								</TextWrapper>
							</Column>
							<Column lg={6}>
								<ImageWrapper theme={theme}>
									<img src={content.imageUrl} css={imageStyles} />
								</ImageWrapper>
							</Column>
						</Row>
					</Grid>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="tertiary">
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<Flex justifyContent="space-between">
						<FlexItem>
							<Typography variant="h1" color={theme.colors.primary.white}>
								{content.title}
							</Typography>
						</FlexItem>
						<FlexItem>
							<LogoWrapper theme={theme}>
								<img src={content.logoUrl} css={logoStyles} />
							</LogoWrapper>
						</FlexItem>
					</Flex>

					<Typography variant="p" color={theme.colors.primary.white} size="xl">
						{content.subTitle}
					</Typography>
					<ImageWrapper theme={theme}>
						<img src={content.imageUrl} css={imageStyles} />
					</ImageWrapper>
					{content.description.map((string: string, index: number) => {
						return (
							<Typography
								variant="p"
								color={theme.colors.primary.white}
								size="md"
								key={index}
							>
								{string}
							</Typography>
						);
					})}
				</ContentSection>
			</Media>
		</>
	);
};

export default TurretHero;
