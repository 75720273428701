import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";
import { ButtonLink } from "@ryerson/frontend.navigation";
import { Typography } from "@ryerson/frontend.typography";
import { ContentSection } from "@ryerson/frontend.layout";
import { Grid, Column, Row } from "@ryerson/frontend.layout";
import { TextImageSlide, Section, Bullet } from "../TextImageSlide";

const Divider = styled.hr`
	width: 100%;
	opacity: 20%;
	margin-top: 59px;
	margin-bottom: 55px;
	${(props: any) => {
		const { theme } = props;
		return css`
			color: ${theme.colors.primary.darkGray};
		`;
	}}
`;

const TextContainer = styled.div`
	display: block;
	width: 85%;
	padding-bottom: 40px;
	height: auto;
	${(props: TextColumnProps) => {
		return css`
			margin: ${props.paddingDirection === "left" ? "0 auto 0 0" : " 0 0 0 auto"};
		`;
	}}
`;

type TextColumnProps = {
	section: Section;
	paddingDirection: string;
};

export default (props: TextImageSlide) => {
	const { theme } = useTheme();

	const TextColumn = (props: TextColumnProps) => {
		const section = props.section;

		return (
			<TextContainer {...props}>
				<Typography variant="h2">{section.title}</Typography>
				<Typography
					variant="p"
					size="lg"
					css={css`
						margin-top: 32px;
						margin-bottom: 24px;
					`}
				>
					{section.description}
				</Typography>
				{section.description2 ? (
					<>
						<Typography
							variant="p"
							size="lg"
							css={css`
								margin-top: 32px;
								margin-bottom: 24px;
							`}
						>
							{section.description2}
						</Typography>
					</>
				) : (
					<></>
				)}
				<Grid>
					<Row>
						<Column lg={6}>
							{section.benefitsSection ? (
								<>
									<Typography variant="h3">
										{section.benefitsSection.title.length > 0
											? section.benefitsSection.title
											: "\u00A0"}
									</Typography>

									<ul
										style={{
											paddingLeft: "17.5px",
										}}
									>
										{section.benefitsSection.bullet.map(
											(bullet: Bullet, idx: number) => {
												return (
													<li>
														{bullet.bold ? (
															<>
																<Typography
																	variant="span"
																	weight="bold"
																	size="md"
																>
																	{bullet.bold}
																</Typography>
																<Typography
																	variant="span"
																	size="md"
																>
																	{bullet.normal}
																</Typography>
															</>
														) : (
															<Typography variant="span" size="md">
																{bullet.normal}
															</Typography>
														)}
													</li>
												);
											}
										)}
									</ul>
								</>
							) : (
								<></>
							)}
						</Column>
						<Column lg={6}>
							{section.capabilitiesSection ? (
								<>
									<Typography variant="h3">
										{section.capabilitiesSection.title.length > 0
											? section.capabilitiesSection.title
											: "\u00A0"}
									</Typography>

									<ul
										style={{
											paddingLeft: "17.5px",
										}}
									>
										{section.capabilitiesSection.bullet.map(
											(bullet: Bullet, idx: number) => {
												return (
													<li>
														{bullet.bold ? (
															<>
																<Typography
																	variant="span"
																	weight="bold"
																	size="md"
																>
																	{bullet.bold}
																</Typography>
																<Typography
																	variant="span"
																	size="md"
																>
																	{bullet.normal}
																</Typography>
															</>
														) : (
															<Typography variant="span" size="md">
																{bullet.normal}
															</Typography>
														)}
													</li>
												);
											}
										)}
									</ul>
								</>
							) : (
								<></>
							)}
						</Column>
					</Row>
					<Row>
						<Column lg={6}>
							<ButtonLink
								to={section.actionLink ?? "#"}
								type="primary"
								orientation="left"
								linkType="internal"
								label={section.actionText}
							/>
						</Column>
						<Column lg={6}>
							<></>
						</Column>
					</Row>
				</Grid>
			</TextContainer>
		);
	};

	return (
		<>
			{props.sections.map((section: Section, index: number) => {
				return (
					<ContentSection
						vPadding="104px"
						type={index % 2 === 0 ? "primary" : "secondary"}
					>
						{index === 0 ? (
							<>
								<Typography variant="h1">{props.title}</Typography>
								<Divider theme={theme} />
							</>
						) : (
							<></>
						)}
						<Grid>
							{index % 2 === 0 ? (
								<Row>
									<Column lg={6}>
										<TextColumn section={section} paddingDirection="left" />
									</Column>
									<Column lg={6}>
										<img css={{ width: "100%" }} src={section.imageUrl}></img>
									</Column>
								</Row>
							) : (
								<Row>
									<Column lg={6}>
										<img css={{ width: "100%" }} src={section.imageUrl}></img>
									</Column>
									<Column lg={6}>
										<TextColumn section={section} paddingDirection="right" />
									</Column>
								</Row>
							)}
						</Grid>
					</ContentSection>
				);
			})}
		</>
	);
};
