import React from "react";
import { Media } from "@ryerson/frontend.layout";
import CompanyDesktop from "../Company/Desktop/CompanyDesktop";
import CompanyMobile from "../Company/Mobile/CompanyMobile";

export interface CompanyDetails {
	companyName: string;
	title: string;
	description: string[];
	linkText: string | string[];
	logoImageUrl: string;
	hasVideo: boolean;
	videoUrl?: string;
	imageUrl?: string[];
	linkUrl: string;
	linkExternal: boolean;
	imageStrings?: string[];
}

export interface CompanyCard {
	index?: number | undefined;
	cardType: "mediaLeft" | "mediaRight" | "multiImage";
	companyDetails: CompanyDetails;
}

export interface Companies {
	companies: CompanyCard[];
}

export type CompanyProps = {
	companyCard: CompanyCard;
};

export type CompanyComponentProps = {
	cardType: "mediaLeft" | "mediaRight" | "multiImage";
	index?: number;
	content: CompanyDetails;
};

const Company: React.FC<CompanyProps> = ({ companyCard }) => {
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<CompanyDesktop
					cardType={companyCard.cardType}
					index={companyCard.index}
					content={companyCard.companyDetails}
				/>
			</Media>
			<Media lessThan="lg">
				<CompanyMobile
					cardType={companyCard.cardType}
					index={companyCard.index}
					content={companyCard.companyDetails}
				/>
			</Media>
		</>
	);
};

export default Company;
