import React from "react";
import { AllSections, Sections } from "@components/Shared/AllSections/AllSections";
import Layout from "@components/Shared/Templates/Layout";
import { useApplication } from "@ryerson/frontend.application";
import ContactUs from "@components/Shared/ContactUsRefactor/ContactUs";
import TurretHero from "@components/TurretSteel/Hero/Hero";
import ProductsAndServices from "@components/TurretSteel/ProductsAndServices/ProductsAndServices";
import OurExperts from "@components/TurretSteel/OurExperts/OurExperts";
import TurretIndustries from "@components/TurretSteel/IndustriesWeServe/IndustriesWeServe";
import AwardsComponent from "@components/TurretSteel/Awards/Awards";
import Meta from "@components/Shared/model/MetaHelmet";
import { useHasMounted } from "@components/Hooks";
import {
	MetaObject,
	HeroContent,
	ProductsServicesContent,
	IndustriesContent,
	ExpertsContent,
	AwardsMultilingualContent,
	ContactContent,
} from "@content/ryerson/about-us/family-of-companies/turret-steel";

export default () => {
	const {
		localization: { language },
	} = useApplication();

	const sections: Sections[] = [];
	sections.push({
		title: ProductsServicesContent[language].title,
		cmp: <ProductsAndServices content={ProductsServicesContent[language]} />,
	});
	sections.push({
		title: IndustriesContent[language].title,
		cmp: <TurretIndustries content={IndustriesContent[language]} />,
	});
	sections.push({
		title: ExpertsContent[language].title,
		cmp: <OurExperts content={ExpertsContent[language]} />,
	});
	if (!useHasMounted()) return null;
	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<TurretHero content={HeroContent[language]} />
			<AwardsComponent content={AwardsMultilingualContent[language]} />
			<ContactUs
				background="secondary"
				withImage={false}
				oneButtonVariant={false}
				content={ContactContent[language]}
			/>
			<AllSections sections={sections} />
		</Layout>
	);
};
