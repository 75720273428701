import React from "react";
import { Media } from "@ryerson/frontend.layout";
import TextImageSlideMobile from "./Mobile/TextImageSlideMobile";
import TextImageSlideDesktop from "./Desktop/TextImageSlideDesktop";

export type Bullet = {
	bold?: string;
	normal: string;
};

export type BulletPoints = {
	title: string;
	bullet: Bullet[];
};

export type Section = {
	title: string;
	description: string;
	description2?: string;
	benefitsSection?: BulletPoints;
	capabilitiesSection?: BulletPoints;
	actionText: string;
	actionLink?: string;
	imageUrl: string;
};

export interface TextImageSlide {
	title: string;
	navLabel: string;
	sections: Section[];
}

export default (props: TextImageSlide) => {
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<TextImageSlideDesktop {...props} />
			</Media>
			<Media lessThan="lg">
				<TextImageSlideMobile {...props} />
			</Media>
		</>
	);
};
