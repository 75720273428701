import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { css } from "@emotion/react";
import rgba from "polished/lib/color/rgba";
import styled from "@emotion/styled";
import { ProductsAndServicesProps } from "../ProductsAndServices";
import { Grid, Row, Column } from "@ryerson/frontend.layout";

const MainCard = styled(FlexItem)`
	position: relative;
	width: 100%;
	min-height: 200px;
	${(props: any) => {
		const { theme } = props;
		return css`
			:not(:last-child) {
				border-bottom: 1px solid ${rgba(theme.colors.primary.secondaryBrand, 0.1)};
			}
		`;
	}}
`;
const CardTitle = styled(Typography)`
	display: block;
`;
const CardList = styled.div`
	position: absolute;
	display: block;
`;
const ImageWrapper = styled.div`
	margin-top: 15px;
`;
const UL = styled.ul`
	margin: 0;
	padding-left: 20px;
`;

const List = (props: any) => {
	const { theme } = useTheme();
	return (
		<UL>
			{props.list.map((item: string, index: number) => {
				return (
					<li key={index}>
						<Typography color={theme.colors.primary.text} variant="span" size="sm">
							{item}
						</Typography>
					</li>
				);
			})}
		</UL>
	);
};

const Card = (props: any) => {
	const { theme } = useTheme();
	return (
		<MainCard theme={theme} {...props}>
			<Grid>
				<Row>
					<Column xs={3} md={4}>
						<ImageWrapper theme={theme}>
							<img src={props.imageUrl} />
						</ImageWrapper>
					</Column>
					<Column xs={9} md={8}>
						<Column xs={12}>
							<CardTitle
								variant="p"
								size="md"
								color={theme.colors.primary.header}
								weight="bolder"
							>
								{props.title}
							</CardTitle>
						</Column>
						<Column xs={12}>
							<CardList>
								<Grid>
									{props.column_2 ? (
										<Row>
											<Column xs={6}>
												{" "}
												<List list={props.column_1} />{" "}
											</Column>
											<Column xs={6}>
												{" "}
												<List list={props.column_2} />{" "}
											</Column>
										</Row>
									) : (
										<Row>
											<Column xs={12}>
												{" "}
												<List list={props.column_1} />{" "}
											</Column>
										</Row>
									)}
								</Grid>
							</CardList>
						</Column>
					</Column>
				</Row>
			</Grid>
		</MainCard>
	);
};

const ProductsAndServicesMobile: React.FC<ProductsAndServicesProps> = (props) => {
	const { theme } = useTheme();
	return (
		<ContentSection type="primary" hPadding="0">
			<Flex direction="column">
				{props.content.cards.map(function (content: any, index: number) {
					return <Card key={index} theme={theme} {...content} />;
				})}
			</Flex>
		</ContentSection>
	);
};
export default ProductsAndServicesMobile;
