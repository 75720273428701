import React from "react";
import { AllSections, Sections } from "@components/Shared/AllSections/AllSections";
import Layout from "@components/Shared/Templates/Layout";
import { useApplication } from "@ryerson/frontend.application";
import InOurDNA from "@components/Shared/InOurDNA/InOurDNA";
import { InOurDNAContent } from "@components/Shared/InOurDNA/Content";
import CareersHero from "@components/GrowWithSales/Hero/Hero";
import IgniteYourCareer from "@components/Careers/IgniteYourCareer/IgniteYourCareer";
import { IgniteYourContent, LanguageParallax } from "@components/Careers/IgniteYourCareer/Content";
import Benefits from "@components/WorkingAtRyerson/Benefits/Benefits";
import { HearFromOurContent } from "@components/Careers/HearFromOurPeople/Content";
import HearFromOurPeople from "@components/Careers/HearFromOurPeople/HearFromOurPeople";
import { useTheme } from "@ryerson/frontend.theme";
import Meta from "@components/Shared/model/MetaHelmet";
import { JoinBenefitsContent, HeroContent, MetaObject } from "@content/ryerson/about-us/careers";

export default () => {
	const {
		localization: { language },
	} = useApplication();
	const { theme } = useTheme();
    const parallax = LanguageParallax[language];
	const sections: Sections[] = [];
	sections.push({
		title: IgniteYourContent[language].title1 + " " + IgniteYourContent[language].title2,
		cmp: <IgniteYourCareer 
                rightBackground={theme.colors.primary.darkerGray}
                title1Left={parallax.title1Left}
				title2Left={parallax.title2Left}
				title1LeftSmaller={parallax.title1LeftSmaller}
				title2LeftSmaller={parallax.title2LeftSmaller}
				title1Parallax={parallax.title1Parallax}
				title2Parallax={parallax.title2Parallax}
				title1ParallaxSmaller={parallax.title1ParallaxSmaller}
				title2ParallaxSmaller={parallax.title2ParallaxSmaller}
                customFontSize={parallax.customFontSize}
            />,
		maxHeight: "5000px",
	});
	sections.push({
		title: InOurDNAContent[language].title,
		cmp: <InOurDNA background="primary" />,
	});
	sections.push({
		title: JoinBenefitsContent[language].title,
		cmp: <Benefits content={JoinBenefitsContent[language]} />,
	});
	sections.push({
		title: HearFromOurContent[language].title,
		cmp: <HearFromOurPeople content={HearFromOurContent[language]} />,
	});
	// sections.push({
	// 	title: AwardContent[language].title,
	// 	cmp: <Awards content={AwardContent[language]} />,
	// });
	return (
		<Layout>
			<>
				<Meta content={MetaObject[language]} />
				<CareersHero content={HeroContent[language]} />
				<AllSections sections={sections} />
			</>
		</Layout>
	);
};
