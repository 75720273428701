import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { useApplication } from "@ryerson/frontend.application";
import GlossaryPage, { GlossaryPageSetup, GlossaryHero } from "@components/Glossary/Page/Page";
import Meta, { MetaContent } from "@components/Shared/model/MetaHelmet";
import { useHasMounted } from "@components/Hooks";

const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Metal Glossary - Glossary of Steel Terms - Ryerson",
		description: `This ever-expanding glossary is your guide to the most commonly used terms and phrases in metal.`,
		url: `/metal-resources/references/glossary`,
		company: true,
	},
	fr: {
		title: "Metal Glossary - Glossary of Steel Terms - Ryerson",
		description: `This ever-expanding glossary is your guide to the most commonly used terms and phrases in metal.`,
		url: `/metal-resources/references/glossary`,
		company: true,
	},
	es: {
		title: "Metal Glossary - Glossary of Steel Terms - Ryerson",
		description: `This ever-expanding glossary is your guide to the most commonly used terms and phrases in metal.`,
		url: `/metal-resources/references/glossary`,
		company: true,
	},
};

const HeroContent: LanguageContent<GlossaryHero> = {
	en: {
		title: "Glossary",
		searchPlaceholder: "Search the glossary",
		description:
			"This ever-expanding glossary is your guide to the most commonly used terms and phrases in metal.",
	},
	fr: {
		title: "Glossaire",
		searchPlaceholder: "Rechercher dans le glossaire",
		description:
			"Ce glossaire en constante évolution est votre guide des termes et expressions les plus couramment utilisés dans le domaine du métal.",
	},
	es: {
		title: "Glosario",
		searchPlaceholder: "Buscar en el glosario",
		description:
			"Este glosario en constante expansión es su guía de los términos y frases más utilizados en metal.",
	},
};

export const GlossaryPageContent: LanguageContent<GlossaryPageSetup> = {
	en: {
		contactLabel: "Do you have an unanswered question?",
		contactButton: "Contact Us",
		contactChat: "Live Chat",
		searchResults: "search results for",
		clearResults: "Clear Results",
	},
	fr: {
		contactLabel: "Avez-vous une question sans réponse?",
		contactButton: "Nous contacter",
		contactChat: "Chat en direct",
		searchResults: "Résultats de recherche pour",
		clearResults: "Effacer les résultats",
	},
	es: {
		contactLabel: "¿Tienes una pregunta sin respuesta?",
		contactButton: "Contacta con nosotros",
		contactChat: "Chat en vivo",
		searchResults: "resultados de búsqueda de",
		clearResults: "Borrar resultados",
	},
};

export default () => {
	const {
		localization: { language },
	} = useApplication();
	if (!useHasMounted()) return null;
	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<GlossaryPage content={GlossaryPageContent[language]} hero={HeroContent[language]} />
		</Layout>
	);
};
