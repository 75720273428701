import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";
import { ButtonLink } from "@ryerson/frontend.navigation";
import { Typography } from "@ryerson/frontend.typography";
import { ContentSection } from "@ryerson/frontend.layout";
import { TextImageSlide, Section, Bullet } from "../TextImageSlide";

const Divider = styled.hr`
	width: 100%;
	margin-bottom: 18px;
	margin-top: 0px;
	${(props: any) => {
		const { theme } = props;
		return css`
			color: ${theme.colors.primary.secondaryBrand};
		`;
	}}
`;

export default (props: TextImageSlide) => {
	const { theme } = useTheme();
	return (
		<>
			{props.sections.map((section: Section, index: number) => {
				return (
					<ContentSection type="primary" hPadding="0">
						{index !== 0 ? <Divider theme={theme} /> : <></>}
						<img css={{ width: "100%" }} src={section.imageUrl}></img>
						<Typography variant="h4">{section.title}</Typography>
						<Typography variant="p" size="md">
							{section.description}
						</Typography>
						{section.description2 ? (
							<>
								<Typography variant="p" size="md">
									{section.description}
								</Typography>
							</>
						) : (
							<></>
						)}
						<>
							{section.benefitsSection ? (
								<>
									<Typography variant="p" size="md" weight="bold">
										{section.benefitsSection.title}
									</Typography>

									<ul
										style={{
											paddingLeft: "17.5px",
										}}
									>
										{section.benefitsSection.bullet.map(
											(bullet: Bullet, idx: number) => {
												return (
													<li>
														{bullet.bold ? (
															<>
																<Typography
																	variant="span"
																	weight="bold"
																	size="xs"
																>
																	{bullet.bold}
																</Typography>
																<Typography
																	variant="span"
																	size="xs"
																>
																	{bullet.normal}
																</Typography>
															</>
														) : (
															<Typography variant="span" size="xs">
																{bullet.normal}
															</Typography>
														)}
													</li>
												);
											}
										)}
									</ul>
								</>
							) : (
								<></>
							)}
						</>
						<>
							{section.capabilitiesSection ? (
								<>
									<Typography variant="p" size="md" weight="bold">
										{section.capabilitiesSection.title}
									</Typography>

									<ul
										style={{
											paddingLeft: "17.5px",
										}}
									>
										{section.capabilitiesSection.bullet.map(
											(bullet: Bullet, idx: number) => {
												return (
													<li>
														{bullet.bold ? (
															<>
																<Typography
																	variant="span"
																	weight="bold"
																	size="xs"
																>
																	{bullet.bold}
																</Typography>
																<Typography
																	variant="span"
																	size="xs"
																>
																	{bullet.normal}
																</Typography>
															</>
														) : (
															<Typography variant="span" size="xs">
																{bullet.normal}
															</Typography>
														)}
													</li>
												);
											}
										)}
									</ul>
								</>
							) : (
								<></>
							)}
						</>
						<ButtonLink
							to={section.actionLink ?? "#"}
							label={section.actionText}
							orientation="left"
							linkType="internal"
						/>
					</ContentSection>
				);
			})}
		</>
	);
};
