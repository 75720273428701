import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { Media } from "@ryerson/frontend.layout";
import { useApplication } from "@ryerson/frontend.application";
import { AllSections, Sections } from "@components/Shared/AllSections/AllSections";
import TextImage from "@components/BarTubeStructuralProcessing/TextImageSlide/TextImageSlide";
import SolutionsWeOffer from "@components/Solutions/ParallaxSolutionsWeOffer/SolutionsWeOffer";
import RyersonDifferenceSection from "@components/Shared/RyersonDifference/RyersonDifferenceSection";
import { RyersonContent } from "@components/Shared/RyersonDifference/Content2";
import WhatOurCustomersCustom from "@components/Shared/WhatOurCustomers/WhatOurCustomersCustom";
import { WhatOurContentSays } from "@components/Shared/WhatOurCustomers/RefactorContent";
import FrequentlyAskedQuestionsSection from "@components/Shared/FrequentlyAskedQuestions/FrequentlyAskedQuestionsSection";
import MetalExperts from "@components/Shared/YourMetalExperts/YourMetalExperts";
import { YourStaticContent } from "@components/Shared/YourMetalExperts/Content";
import ExploreOtherCapabilitiesSolutions from "@components/BarTubeStructuralProcessing/ExploreOtherCapabilitiesSolutions/ExploreOtherCapabilitiesSolutions";
import ContactUsRefactor from "@components/Shared/ContactUsRefactor/ContactUs";
import { DefaultContactUsContent } from "@components/Shared/ContactUsRefactor/Content";
import GrowHero from "@components/GrowWithSales/Hero/Hero";
import { FAQContent } from "@components/Shared/FrequentlyAskedQuestions/Content";
import MetalInsightsAndResourcesRefactor, {
	defaultStaticContent as MetalInsightsContent,
} from "@components/Shared/MetalInsights/MetalInsightsAndResourcesRefactor";
import Meta from "@components/Shared/model/MetaHelmet";
import SubNavigation, {
	NavigableItem,
} from "@components/BarTubeStructuralProcessing/SubNavigation/SubNavigation";
import { MetaObject } from "@content/ryerson/metal-solutions/solutions/advanced-processing";
import { HeroContent } from "@content/ryerson/metal-solutions/solutions/advanced-processing";
import { EquipmentAndProcessContent } from "@content/ryerson/metal-solutions/solutions/advanced-processing";
import { WhoDoWeHelpContent } from "@content/ryerson/metal-solutions/solutions/advanced-processing";
import { ExploreOtherCapabilitiesSolutionsContent } from "@content/ryerson/metal-solutions/solutions/advanced-processing";

export default () => {
	const {
		localization: { language },
	} = useApplication();
	const sections: Sections[] = [];
	const subNav: NavigableItem[] = [];

	sections.push({
		title: EquipmentAndProcessContent[language].title,
		cmp: <TextImage {...EquipmentAndProcessContent[language]} />,
	});
	sections.push({
		title: RyersonContent[language].title1 + " " + RyersonContent[language].title2,
		cmp: <RyersonDifferenceSection />,
	});
	sections.push({
		title: WhoDoWeHelpContent[language].title,
		cmp: <SolutionsWeOffer background="tertiary" content={WhoDoWeHelpContent[language]} />,
	});
	sections.push({
		title: WhatOurContentSays[language].title,
		cmp: <WhatOurCustomersCustom />,
	});
	sections.push({
		title: FAQContent[language].title1 + " " + FAQContent[language].title2,
		cmp: <FrequentlyAskedQuestionsSection />,
	});
	sections.push({
		title: YourStaticContent[language].navLabel,
		cmp: <MetalExperts />,
	});
	sections.push({
		title:
			MetalInsightsContent[language].title1 +
			" " +
			MetalInsightsContent[language].titleSeparator +
			" " +
			MetalInsightsContent[language].title2,
		cmp: <MetalInsightsAndResourcesRefactor />,
	});
	sections.push({
		title: ExploreOtherCapabilitiesSolutionsContent[language].title,
		cmp: (
			<ExploreOtherCapabilitiesSolutions
				{...ExploreOtherCapabilitiesSolutionsContent[language]}
			/>
		),
	});
	sections.push({
		title: DefaultContactUsContent[language].title,
		cmp: <ContactUsRefactor oneButtonVariant={false} />,
		hide: true,
	});
	subNav.push({
		id: EquipmentAndProcessContent[language].title.toLowerCase().replace(/\s+/g, "-"),
		title: EquipmentAndProcessContent[language].navLabel,
	});
	subNav.push({
		id:
			RyersonContent[language].title1.toLowerCase().replace(/\s+/g, "-") +
			"-" +
			RyersonContent[language].title2.toLowerCase().replace(/\s+/g, "-"),
		title: RyersonContent[language].navLabel ?? "",
	});
	subNav.push({
		id: WhoDoWeHelpContent[language].title.toLowerCase().replace(/\s+/g, "-"),
		title: WhoDoWeHelpContent[language].title1,
	});
	subNav.push({
		id: WhatOurContentSays[language].title.toLowerCase().replace(/\s+/g, "-"),
		title: WhatOurContentSays[language].navLabel,
	});
	subNav.push({
		id:
			FAQContent[language].title1.toLowerCase().replace(/\s+/g, "-") +
			"-" +
			FAQContent[language].title2.toLowerCase().replace(/\s+/g, "-"),
		title: FAQContent[language].navLabel,
	});
	subNav.push({
		id: YourStaticContent[language].navLabel.toLowerCase().replace(/\s+/g, "-"),
		title: YourStaticContent[language].navLabel,
	});
	subNav.push({
		id:
			MetalInsightsContent[language].title1.toLowerCase().replace(/\s+/g, "-") +
			"-" +
			MetalInsightsContent[language].titleSeparator.toLowerCase().replace(/\s+/g, "-") +
			"-" +
			MetalInsightsContent[language].title2.toLowerCase().replace(/\s+/g, "-"),
		title:
			MetalInsightsContent[language].title1 +
			" " +
			MetalInsightsContent[language].titleSeparator +
			" " +
			MetalInsightsContent[language].title2,
	});
	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<GrowHero content={HeroContent[language]} />
			<Media greaterThanOrEqual="lg">
				<SubNavigation navigableItems={subNav} />
			</Media>
			<AllSections sections={sections} />
			<Media lessThan="lg">
				<ContactUsRefactor oneButtonVariant={false} background="secondary" />
			</Media>
		</Layout>
	);
};
