import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";
import { ISlideBaseProps } from "@ryerson/frontend.carousel";
import { Flex, FlexItem } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { Button } from "@ryerson/frontend.button";

export interface ICustomSlideProps extends ISlideBaseProps {
	imageUrl: string;
	imageOverlayText?: string;
}

const ImageOverlayContainer = styled.div`
	position: absolute;
	bottom: 20px;
	left: 20px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				bottom: 10px;
				left: 10px;
			}
		`;
	}}
`;

const MultiImageSlide: React.FC<ICustomSlideProps> = ({
	back = () => {},
	next = () => {},
	imageUrl,
	imageOverlayText,
}) => {
	const { theme } = useTheme();
	let imageOverlay = "";
	if (imageOverlayText) {
		imageOverlay = imageOverlayText;
	}

	const BackButton = css`
		margin-right: 24px;
	`;

	const ForwardButton = css`
		margin-left: 24px;
	`;

	const imageStyles = css`
		width: 100%;
		height: auto;
	`;

	return (
		<div>
			<Flex alignItems="center">
				<FlexItem>
					<Button
						css={BackButton}
						label={""}
						onClick={back}
						shape="circle"
						leftIcon="backward"
						foreground="light"
						background={theme.colors.secondary.lightBlue}
						size="sm"
					/>
				</FlexItem>
				<Flex>
					<FlexItem grow={1} css={{ position: "relative" }}>
						<img src={imageUrl} css={imageStyles} />
						{imageOverlay.length > 0 && (
							<ImageOverlayContainer theme={theme}>
								<Typography size="md" color={theme.colors.primary.white}>
									{imageOverlayText}
								</Typography>
							</ImageOverlayContainer>
						)}
					</FlexItem>
				</Flex>
				<FlexItem>
					<Button
						css={ForwardButton}
						label={""}
						onClick={next}
						shape="circle"
						leftIcon="forward"
						foreground="light"
						background={theme.colors.secondary.lightBlue}
						size="sm"
					/>
				</FlexItem>
			</Flex>
		</div>
	);
};

export default MultiImageSlide;
