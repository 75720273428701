import { HeroImageProps } from "./HeroImage";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const STSHeroContent: LanguageContent<HeroImageProps> = {
	en: {
		imageUrl: "/images/sts/home-page/STSHero.jpg",
		titleLine1: "Tool Steel \n is just the",
		titleLine2: "Start",
		actionLink: "/who-we-are",
		actionLabel: ["Learn More", "Here"],
		welcome: "Welcome Back,",
		heroSubTitle1: "Search for a specific item or see your",
		orders: "orders",
		and: "and",
		products: "products",
	},
	fr: {
		imageUrl: "/images/sts/home-page/STSHero.jpg",
		titleLine1: "L'acier à outils \n n'est que le",
		titleLine2: "début",
		actionLink: "/who-we-are",
		actionLabel: ["Découvrez comment", "ici"],
		welcome: "Bienvenue,",
		heroSubTitle1: "Cherchez un article spécifique ou consultez vos",
		orders: "commandes",
		and: "et",
		products: "produits",
	},
	es: {
		imageUrl: "/images/sts/home-page/STSHero.jpg",
		titleLine1: "El acero para \n herramientas es solo el principio",
		titleLine2: "",
		actionLink: "/who-we-are",
		actionLabel: ["Conozca más", "aquí"],
		welcome: "Bienvenide,",
		heroSubTitle1: "Busque un articulo en particular o vea sus",
		orders: "pedidos",
		and: "y",
		products: "productos",
	},
};
