import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { ContentSection, Flex } from "@ryerson/frontend.layout";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ButtonLink } from "@ryerson/frontend.navigation";
import { MetalMarketIntelligenceProps } from "../MetalMarketIntelligence";
import { Badge } from "@ryerson/frontend.notification";
import { Link } from "gatsby";
import { BlogCategoryMin } from "@components/Blog/Category";

const IntelDiv = styled.div`
	position: relative;
	display: flex;
	width: 20%;
	height: 100px;
	margin-bottom: 40px;
`;

const BadgeContainer = styled.div`
	position: absolute;
	z-index: 1;
	width: 200px;
	left: 0;
	bottom: 0%;
`;

const Spacer = styled.div`
	width: 100%;
	height: 20px;
`;

const Intel = (props: BlogCategoryMin) => {
	return (
		<IntelDiv>
			<Link to={"/metal-resources/metal-market-intelligence/" + props.slug}>
				<img
					src={props.listPageImage.file.url}
					css={css`
						width: 200px;
						border-radius: 2px;
						height: 80px;
						object-fit: cover;
					`}
				/>
				<BadgeContainer>
					<Badge badgeType="pill" type="tertiary" color={props.categoryColor}>
						{props.title}
					</Badge>
				</BadgeContainer>
			</Link>
		</IntelDiv>
	);
};

const MetalMarketIntelligenceDesktop: React.FC<MetalMarketIntelligenceProps> = (props) => {
	const { theme } = useTheme();

	return (
		<>
			<div
				css={css`
					background-image: url(${props.imageUrl});
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center;
					margin: 0 auto;
					height: 251px;
					max-width: 1440px;
					width: 100%;
					padding-top: 120px;
					box-sizing: border-box;
				`}
			>
				<Typography
					variant="h1"
					type="tertiary"
					css={css`
						width: 100%;
						max-width: 1160px;
						margin: 0 auto;
						letter-space: -4%;
					`}
				>
					{props.title}
				</Typography>
			</div>
			<ContentSection type="secondary" vPadding="30px">
				<Typography
					variant="p"
					css={css`
						margin-bottom: 40px;
					`}
				>
					{props.description}
				</Typography>
				<Flex
					direction="row"
					wrap="wrap"
					css={css`
						width: 100%;
						@media only screen and (max-width: ${theme.breakpoints.xl}) {
						}
					`}
				>
					{props.blogCategories.map((content: BlogCategoryMin, index: number) => {
						return <Intel key={index} {...content} />;
					})}
				</Flex>
				<Spacer />
				<ButtonLink to={props.actionLink} label={props.actionText} />
				<Spacer />
			</ContentSection>
		</>
	);
};
export default MetalMarketIntelligenceDesktop;
